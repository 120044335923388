import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Tests from "./pages/Tests";
import SingleTest from "./pages/SingleTest";
import About from "./pages/About";
import NotFound from "./pages/NotFound";


function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tests" element={<Tests />} />
          <Route path="/quizes/:test" element={<SingleTest />} />
          {/* <Route path="/addtest" element={<PrivateRoute />}>
            <Route path="/addtest" element={<AddTest />} />
          </Route>
          <Route path="/addsign" element={<PrivateRoute />}>
            <Route path="/addsign" element={<AddSignTest />} />
          </Route>
          <Route path="/addcitizenship" element={<PrivateRoute />}>
            <Route path="/addcitizenship" element={<AddCitizenship />} />
          </Route>
          <Route path="/addquiz" element={<PrivateRoute />}>
            <Route path="/addquiz" element={<AddQuiz />} />
          </Route> */}
          <Route path="/about" element={<About />} />
          {/* <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default App;
