import React, { useEffect, useState } from "react";
import { testimonials } from "../data";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaQuoteRight } from "react-icons/fa";
import classes from './Testimonials.module.css'

const Testimonials = () => {
  const [index, setIndex] = useState(0);
  const [people, setPeople] = useState(testimonials);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);
  return (
    <div className="pt-12 bg-white md:py-40 text-neutral-900">
      <div className={classes.section}>
        <div className={classes.title}>
          <h2 className="text-xl font-bold md:text2xl lg:text-3xl">موفقیت در امتحانات</h2>
        </div>
        <div className={classes['section-center']}>
          {people.map((person, personIndex) => {
            const { id, name, date, quote, img } = person;

            let position = classes.nextSlide;
            if (personIndex === index) {
              position = classes.activeSlide;
            }
            if (
              personIndex === index - 1 ||
              (index === 0 && personIndex === people.length - 1)
            ) {
              position = classes.lastSlide;
            }

            return (
              <article key={id} className={`${position} flex flex-col items-center`}>
                <img src={img} alt={name}  className={`md:${classes['person-img']} w-40 h-40 rounded-full object-cover`}/>
                <h4 className="my-4 font-semibold">{name}</h4>
                <p className={classes.title}><span className="block mb-2">:تاریخ امتحان</span>{date}</p>
                <p className={classes.text}>{quote}</p>
                <FaQuoteRight className={`md:${classes.icon} w-6 h-6 mt-4`}/>
              </article>
            );
          })}

          <button onClick={() => setIndex(index - 1)} className={classes.prev}>
            <FiChevronLeft />
          </button>
          <button onClick={() => setIndex(index + 1)} className={classes.next}>
            <FiChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
