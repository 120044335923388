import React from 'react'
import Header from '../components/Header'
import How from '../components/How'
import Process from '../components/Process'
import Testimonials from '../components/Testimonials'

const Home = () => {
  return (
    <>
        <Header />
        <Process />
        <How />
        <Testimonials />
    </>
  )
}

export default Home