import React, { useEffect, useState } from "react";
import {useSelector} from 'react-redux'
import Loading from "../Loading";
import Modal from "./Modal";

const Quizes = () => {
  const [exams, setExams] = useState([]);
  const [score, setScore] = useState(0);
  const [choose, setChoose] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [page, setPage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [theAnswer, setTheAnswer] = useState("");

const {quizes} = useSelector(state => state.tests)

  
  useEffect(() => {
      setExams(quizes[page]);
  }, [ page]);

  const handleClick = (index, answer) => {
    setScore((prev) => {
      setCorrectAnswer(`آفرین. جواب شما درست است`);
      if (index + 1 === answer) {
        setTheAnswer("#115e59");
        return prev + 1;
      } else {
        setTheAnswer("#9f1239");
        setCorrectAnswer(
          ` این جواب اشتباه است. جواب درست گزینه ${answer} می باشد`
        );
        return prev;
      }
    });

    setChoose(index);
  };

  useEffect(() => {
    setTimeout(() => {
      return <Loading />
    }, 1000)
  }, [])
  const nextQuiz = () => {
    setPage((prev) => {
      if (prev + 1 >= quizes.length) {
        let newPage = 0;
        return newPage;
      } else {
        return prev + 1;
      }
    });
    setCurrentQuestion(0);
    setScore(0);
    setChoose(null);
    setShowModal(false);
  };

  const repeateQuize = () => {
    setPage((prev) => prev);
    setCurrentQuestion(0);
    setScore(0);
    setChoose(null);
    setShowModal(false);
  };
  return (
    <>
      <div className="max-w-[1540px] mx-auto h-full my-12 md:min-h-[520px]">
        <div className="flex flex-col items-center justify-center gap-8">
          <h2 className="my-12 text-2xl font-bold md:text-3xl  md:mr-[-150px]">
            سئوالات امتحانی پرتکرار
          </h2>
          <h3 className="px-6 py-2 text-xl font-semibold text-center text-white bg-sky-800 rounded-md shadow-md  md:mr-[-150px]">
            <span>{page + 1}</span> سئوالات بخش
          </h3>
          <div className="grid lg:grid-cols-[250px_minmax(750px,_1fr)_100px] gap-3">
          
              <ul className="flex flex-col items-center justify-start px-4 my-8 lg:gap-3 lg:grid lg:grid-cols-2">
                {quizes &&
                  quizes.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => setPage(index)}
                        className="w-full py-3 mt-3 font-semibold text-center text-white bg-teal-800 rounded-md shadow-md cursor-pointer"
                      >
                        <span>{index + 1}</span> بخش <p className="inline-block lg:hidden">سئوالات</p>
                      </li>
                    );
                  })}
              </ul>
           

            <section className="border-green-900 border-y-4 md:border-y-0">
              {exams &&
                exams.map((exam, index) => {
                  const { question, answers, correctAnswer: correctAnswerNumber } = exam;
                  const selected = "bg-gray-900 text-white";
                  return (
                    <div
                      style={{
                        display: currentQuestion === index ? "block" : "none",
                      }}
                      key={index}
                    >
                      <h3 className="p-2 my-4 text-xl text-right text-white bg-teal-800 rounded-md shadow-md">
                        {question}
                      </h3>
                      <div className="p-3 border border-gray-900">
                        {answers &&
                          answers.map((answer, index) => {
                            return (
                              <p
                                className={`p-2 mb-2 text-right font-[500] rounded-sm shadow-sm cursor-pointer ${
                                  choose === index && selected
                                }`}
                                key={index}
                                onClick={() =>
                                  handleClick(index, correctAnswerNumber)
                                }
                                
                              >
                                {answer}
                              </p>
                            );
                          })}
                        {correctAnswer && (
                          <p
                            style={{
                              color: "white",
                              background: theAnswer,
                              justifyContent: "end",
                              textAlign: "right",
                              fontWeight: '500'
                            }}
                          >
                            {correctAnswer}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </section>
          </div>
          <div className="flex items-center justify-around w-full max-w-[800px]">
            {/* <button
              className="px-8 py-2 font-semibold bg-white rounded-md shadow-md cursor-pointer text-neutral-900"
              onClick={() =>
                setCurrentQuestion((prev) => {
                  if (prev === 1) {
                    return ;
                  } else {
                    return prev - 1;
                  }
                })
              }
            >
              سوال قبلی
            </button> */}
            <button
              className="px-8 py-2 font-semibold bg-teal-800 text-white rounded-md shadow-md cursor-pointer  lg:mt-[-400px] lg:mr-[-150px]"
              onClick={() =>
                setCurrentQuestion((prev) => {
                  setCorrectAnswer(null);
                  setChoose(null);
                  if (prev < exams.length - 1) {
                    return prev + 1;
                  } else {
                    setShowModal(true);
                    return exams.length;
                  }
                })
              }
            >
              سوال بعدی
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          score={score}
          totalQuestions={exams.length}
          onReset={nextQuiz}
          onRepeat={repeateQuize}
        />
      )}
    </>
  );
};

export default Quizes;
