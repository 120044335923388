import React from "react";

const Modal = ({ score, totalQuestions, onReset, onRepeat }) => {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-gradient-to-t from-neutral-500 to-neutral-600">
      <div className="w-full max-w-[750px] h-full max-h-[450px] bg-white rounded-md flex flex-col items-center justify-center">
        <h2 className="mx-auto my-4 text-2xl font-bold md:text-3xl text-neutral-800">
          امتحان به پایان رسید
        </h2>
        <p className="mb-4 text-xl font-semibold text-gray-800">
          
          <span>{score}</span>
          <span>/</span>
          <span>{totalQuestions}</span>
          <span className="inline-block mx-4">امتیاز شما</span>
        </p>
        <button
          className="block px-8 py-3 mb-4 font-semibold text-white bg-green-800 rounded-md shadow-md md:px-16 md:py-4"
          onClick={onReset}
        >
          تست بعدی
        </button>
        <button
          className="block px-8 py-3 font-semibold text-white bg-yellow-600 rounded-md shadow-md md:px-16 md:py-4"
          onClick={onRepeat}
        >
          شروع دوباره همین تست
        </button>
      </div>
    </div>
  );
};

export default Modal;
