import React from "react";
import { useParams } from "react-router-dom";
import Citizenship from "../components/singlePage/Citizenship";
import General from "../components/singlePage/General";
import Quizes from "../components/singlePage/Quizes";
import Signs from "../components/singlePage/Signs";

const SingleTest = () => {
  const {test} = useParams();

  if (test === "general") {
    return <General />;
  }
  if (test === "signs") {
    return <Signs />;
  }
  if (test === "exams") {
    return <Quizes />;
  }
  if (test === "citizenship") {
    return <Citizenship />;
  }
};

export default SingleTest;
