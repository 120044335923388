import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "./Modal";

const Signs = () => {
  const [signTests, setSignTests] = useState([]);
  const [score, setScore] = useState(0);
  const [choose, setChoose] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [page, setPage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [theAnswer, setTheAnswer] = useState("");

  const {signs} = useSelector(state => state.tests)

  useEffect(() => {
    setSignTests(signs[page]);
  }, [page]);

  const handleClick = (index, answer) => {
    setScore((prev) => {
      setCorrectAnswer(`آفرین. جواب شما درست است`);
      if (index + 1 === answer) {
        setTheAnswer("#115e59");
        return prev + 1;
      } else {
        setTheAnswer("#9f1239");
        setCorrectAnswer(
          ` این جواب اشتباه است. جواب درست گزینه ${answer} می باشد`
        );
        return prev;
      }
    });

    setChoose(index);
  };

  const nextQuiz = () => {
    setPage((prev) => {
      if (prev + 1 >= signs.length) {
        let newPage = 0;
        return newPage;
      } else {
        return prev + 1;
      }
    });
    setCurrentQuestion(0);
    setScore(0);
    setChoose(null);
    setShowModal(false);
  };

  const repeateQuize = () => {
    setPage((prev) => prev);
    setCurrentQuestion(0);
    setScore(0);
    setChoose(null);
    setShowModal(false);
  };
  return (
    <>
      <div className="max-w-[1540px] mx-auto h-full min-h-screen my-12">
        <div className="flex flex-col items-center justify-center gap-8">
          <h2 className="my-12 text-2xl font-bold md:text-3xl">
            علائم راهنمایی و رانندگی
          </h2>
          <h3 className="px-6 py-2 mb-4 text-xl font-semibold text-center text-white rounded-md shadow-md bg-sky-800 md:mb-24">
            <span>{page + 1}</span> سئوالات بخش
          </h3>
          <div className="grid md:grid-cols-[250px_minmax(750px,_1fr)_100px] gap-3 relative">
            <ul className="flex flex-col items-center justify-start px-4 my-8">
              {signs &&
                signs.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => setPage(index)}
                    className="w-full py-3 mt-3 font-semibold text-center text-white bg-teal-800 rounded-md shadow-md cursor-pointer"
                  >
                    <span>{index + 1}</span> علامات بخش
                  </li>
                ))}
            </ul>

            <div>
              {signTests &&
                signTests.map((test, index) => {
                  const {
                    question,
                    answers,
                    correctAnswer: correctAnswerNumber,
                    signImg,
                  } = test;
                  const selected = "bg-gray-900 text-white";
                  return (
                    <div
                      style={{
                        display: currentQuestion === index ? "block" : "none",
                      }}
                      key={index}
                    >
                      <div className="flex items-center justify-center md:w-[350px] md:h-[350px] h-[250px] mx-auto">
                        <img
                          src={signImg || ''}
                          alt="sign"
                          className="object-contain w-full h-full"
                        />
                      </div>
                      <h3 className="p-2 my-4 text-xl text-right text-white bg-teal-800 rounded-md shadow-md">
                        {question}
                      </h3>
                      <div className="p-3 border border-gray-900">
                        {answers &&
                          answers.map((answer, index) => (
                            <p
                              className={`p-2 mb-2 text-right font-[500] rounded-sm shadow-sm cursor-pointer ${
                                choose === index && selected
                              }`}
                              key={index}
                              onClick={() =>
                                handleClick(index, correctAnswerNumber)
                              }
                            >
                              {answer}
                            </p>
                          ))}
                        {correctAnswer && (
                          <p
                            style={{
                              color: "white",
                              background: theAnswer,
                              justifyContent: "end",
                              textAlign: "right",
                              fontWeight: '500'
                            }}
                          >
                            {correctAnswer}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* <button
              className="absolute top-[480px] right-2 bg-white rounded-full md:top-40 md:right-[10rem]"
              onClick={() =>
                setCurrentQuestion((prev) => {
                  setCorrectAnswer(null);
                  setChoose(null);
                  if (prev < signTests.length - 1) {
                    return prev + 1;
                  } else {
                    setShowModal(true);
                    return signTests.length;
                  }
                })
              }
            >
              <GrFormNext className="w-10 h-10 cursor-pointer" />
            </button> */}
            {/* <button className="absolute top-[375px] left-2 bg-white  rounded-full md:top-40 md:left-[20rem]">
            <GrFormPrevious className="w-8 h-8 cursor-pointer" />
          </button> */}
          </div>
          <button
              className="w-full max-w-[250px] bg-teal-800 text-white rounded-md shadow-md cursor-pointer px-8 py-2 font-bold"
              onClick={() =>
                setCurrentQuestion((prev) => {
                  setCorrectAnswer(null);
                  setChoose(null);
                  if (prev < signTests.length - 1) {
                    return prev + 1;
                  } else {
                    setShowModal(true);
                    return signTests.length;
                  }
                })
              }
            >
              سئوال بعدی
            </button>
        </div>
      </div>
      {showModal && (
        <Modal
          score={score}
          totalQuestions={signTests.length}
          onReset={nextQuiz}
          onRepeat={repeateQuize}
        />
      )}
    </>
  );
};

export default Signs;
