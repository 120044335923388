import React, { useState } from "react";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";

const AccordionQuestion = ({ item }) => {
  const [show, setShow] = useState(false);
  const {question, answers} = item
  
  
  return (
    <div className="mb-2 border-b border-gray-900">
      <header className="flex items-center justify-between px-4 py-2 mb-2 text-white bg-teal-800 rounded-sm shadow-sm">
        <button onClick={() => setShow(!show)}>
          {show ? <IoMdClose /> : <IoIosArrowDown />}
        </button>
        <h4 className="font-semibold text-right text-white md:ml-4">{question && question}</h4>
      </header>
      {show &&
        answers &&
        answers.map((ans, index) => (
          <p key={index} className="p-2 pr-8 font-[500] text-right text-gray-900">
            {ans}
          </p>
        ))}
    </div>
  );
};

export default AccordionQuestion
