import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import citizenships from "../../data/citizenshipData";
import quizes from '../../data/quizData'
import signs from '../../data/signData'
import getAllTests from "./testServices";
import generals from "../../data/generalData";


const initialState = {
  signs: getAllTests(signs),
  general: getAllTests(generals),
  citizenship: getAllTests(citizenships),
  quizes: getAllTests(quizes),
};

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
});


export default testSlice.reducer