
const quizes = [
    {
     question: "می توانید قانونا در خط کارپول یک بزرگراه رانندگی کنید چنانچه",
      answers: [
        "هنگامی که حداقل تعداد افراد لازم را حمل می کنید",
        "برای سبقت گرفتن از خودرو دیگر",
        "همه خطوط دیگر بخاطر ترافیک سنگین توقف کرده باشند"
      ],
      correctAnswer: 1,
      id: "gwIQLs3"
    },
    {
     question: "برای افراد ۲۱ سال یا بالاتر رانندگی در مواردی که غلظت الکل خون ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ یا بالاتر باشد ممنوع است",
      answers: [
        "۰.۰۵٪ - پنج صدم درصد",
        "۰.۰۸٪ - هشت صدم درصد",
        "۰.۰۱٪ - یک صدم درصد"
      ],
      correctAnswer: 2,
      id: "apYVHre"
    },
    {
     question: "چه موقع می توانید در کنار جدولی که رنگ آن قرمز است پارک کنید",
      answers: [
        "برای سوار کردن یا پیاده کردن مسافران",
        "تحت هیچ شرایط",
        "هنگامی که قصد دارید در خودرو خود بمانید"
      ],
      correctAnswer: 2,
      id: "OVgZ5yq"
    },
    {
     question: "اگر یک فرد پیاده روی خط کشی عابر است",
      answers: [
        "وسایل نقلیه حق تقدم عبور دارند اما رانندگان باید قانونا موظب ایمنی افراد پیاده باشند",
        "ان فرد پیاده باید رعایت حق تقدم دیگران را نماید",
        "این فرد پیاده حق تقدم عبور دارد"
      ],
      correctAnswer: 3,
      id: "PgmY9vR"
    },
    {
     question: "یک خط در مسیر شما وجود دارد و خودرو جلو شما اغلب سرعت خود را بدون دلیل کاهش می دهد. در این موقعیت باید ـــــــــــــــــــــــــــــــــــــــــــــــــــــــ",
      answers: [
        "فاصله خود را خودروی دیگر افزایش دهید",
        "با چراغ های جلو به سرعت علامت دهید تا راننده دیگر را آگاه کنید",
        "در شانه جاده حرکت کنید تا از خودرو عبور کنید "
      ],
      correctAnswer: 1,
      id: "6JRfUHV"
    },
    {
     question: "شما به خط راه آهن نزدیک می شوید که تحت نظارت چراغ های هشدار دهنده و دروازه نمی باشد. شما به تقاطع راه آهن که توسط چراغ های هشدار و دروازه کنترول می شود نزدیک می شوید. عبور از راه آهن در چه صورت ایمن است",
      answers: [
        "حالا، چون دروازه ها بالا هستند",
        "اگر می بینید قطاری نزدیک نمی شود",
        "هنگامی که چراغ هشدار دیگر چشمک نزند"
      ],
      correctAnswer: 3,
      id: "RGUYFjf"
    },
    {
     question: "اگر مامور انتظامی متوجه شود یکی از مسافران کمرند ایمنی نبسته است و یک احضاریه بنویسد کدام یک از موارد زیر درست است",
      answers: [
        "هم شما و هم سرنشین احضاریه دریافت خواهید کرد",
        "اگر سن مسافر شما کمتر از ۱۶ باشد ممکن است احضاریه دریافت کند",
        "مسافر شما احضاریه بدون در نظر گرفتن سن احضاریه دریافت خواهد کرد"
      ],
      correctAnswer: 2,
      id: "DL8nGEs"
    },
    {
     question: "در کدام یک از موارد زیر، باید از بوق خود استفاده کنید",
      answers: [
        "ممکن است از تصادف جلوگیری کنید",
        "برای گذشتن از یک دوچرخه سوار",
        "وسیله نقلیه دیگری، در سر راه شما باشد"
      ],
      correctAnswer: 1,
      id: "B-RzoZb"
    },
    {
     question: "بیاد چراغ های جلو را قبل از موارد زیر روشن کنید",
      answers: [
        "نیم ساعت پس از غروب ",
        "نیم ساعت پیش از غروب",
        "دو ساعت پس از غروب "
      ],
      correctAnswer: 2,
      id: "rqmtEHv"
    },
    {
     question: "شما یک تصادف کوچک کرده اید. هیچ کس صدمه ندیده است و خسارت بسیار اندک است. باید",
      answers: [
        "خودرو خود را به نزدیک ترین مکان که ترافیک را مسدود نکند حرکت دهید",
        "خودرو را در خط ترافیک خود ترک کنید تا نیروهای انتظامی به محل برسند",
        "خودرو خود را به هر دلیل حرکت ندهید "
      ],
      correctAnswer: 1,
      id: "NWqG90y"
    },
    {
     question: "هنگام رانندگی در نزدیکی مناطق کار باید",
      answers: [
        "با دقت از منطقه کار عبور کرده و از پرت شدن حواس جلوگیری کنید",
        "فاصله خود را با خودرو جلو افزایش دهید",
        "سرعت را کاهش داده و به کاری که در حال انجام است نگاه کنید"
      ],
      correctAnswer: 1,
      id: "relaHMX"
    },
    {
     question: "هنگام رانندگی در جاده ای که تقسیم نشده است، یک خودرو وضعیت اضطراری به شما از روبرو با چراغ های چشمک زن و آژیر نزدیک می شود. باید",
      answers: [
        "به سمت لبه راست جاده رفته و صبر کنید تا خودرو کاملا عبور کند",
        "به رانندگی ادامه دهید و سرعت خود را کاهش دهید",
        "فورا توقف کنید حتی اگر در تقاطع باشید"
      ],
      correctAnswer: 1,
      id: "GhLVBQN"
    },
    {
     question: "شما می توانید در صورتی بر روی پل پارک کنید که ",
      answers: [
        "تابلوی توقف آزاد داشته باشد",
        "بتوانید ۱۵۰ فوت از هر طرف را ببینید",
        "فضای کافی برای اینکه کاملا خارج از خط تردد پارک کنید داشته باشید"
      ],
      correctAnswer: 1,
      id: "blo-dlL"
    },
    {
     question: "کدامیک از جملات زیر در مورد دارو و رانندگی صحیح است",
      answers: [
        "همه داروها، نسخه ها یا داروی بدون نسخه ممکن است بر رانندگی تاثیر بگذارند",
        "هر داروی تجویزی را در صورتی که احساس سرگیجه نداشته باشید می توانید مصرف کنید",
        "فقط داروهای غیر قانونی ممکن است رانندگی را مختل کند"
      ],
      correctAnswer: 1,
      id: "ekV2761"
    },
    {
     question: "اگر به بمیاری مبتلا هستید که ممکن است بر رانندگی ایمن شما تاثیر بگذارد باید ـــــــــــــــــــــــــــــــــــــ را مطلع کنید",
      answers: [
        "شرکت بیمه خود",
        "پلیس محلی",
        "اداره راهنمایی و رانندگی کالیفورنیا "
      ],
      correctAnswer: 3,
      id: "lvVOyqu"
    },
    {
     question: "شما در ترافیک بزرگراه هستید و اتومبیل ها به خط شما وارد می شوند. شما قانونا حق تقدم عبور دارید و باید ",
      answers: [
        "اگر می توانید راه را برای ورود خودروی وارده شوند باز کنید",
        "همیشه سرعت خود را کم کنید و به سایر وسایل نقلیه امکان ورود به بزرگراه را بدهید ",
        "همیشه با رنندگی سریع تر از حق تقدم خود استفاده کنید "
      ],
      correctAnswer: 1,
      id: "8XbOrZw"
    },
    {
     question: "هر راننده ای که هنگام دریافت علامت از جانب پلیس، خودرو را به کنار جاده هدایت نکند و عمدا از صحنه فرار کند حتی اگر کسی مجروح نشده باشد ممکن است به روش زیر مجازات شود",
      answers: [
        "شرکت در کلاس های مدیریت خشم",
        "تا هفت سال زندان در زندان ایالتی",
        "جریمه ای کمتر از هزار دلار"
      ],
      correctAnswer: 2,
      id: "5Jce73V"
    },
    {
     question: "پنج وسیله نقلیه، در طول یک خط و یک جهت در یک جاده بدون فاصله به دنبال شما در حال حرکت هستند. وقتی شما این تابلوی سفید (خودروهای کم سرعت از خط اجازه سبقت) را مشاهده می کنید باید: ",
      answers: [
        "به حرکت ادامه داده اما در گوشه راست خط خود حرکت کنید",
        "سرعت خود را کم کنید و به دیگر رانندگان علامت دهید که از شما عبور کنند",
        "به منطقه یا خطوط خط برگردان حرکت کنید تا انها از شما سبقت بگیرند"
      ],
      correctAnswer: 3,
      id: "BtWrep1"
    },
    {
     question: "علامت سفید (محل عبور قطار) به این معنی می باشد که",
      answers: [
        "تقاطع راه آهن کنترل شده – با سرعت عادی ادامه دهید",
        "در جلوی راه آهن توقف کنید و قبل از عبور منتظر علامت ترافیکی باشید",
        "نگاه کنید و گوش دهید و آماده باشید تا در صورت لزوم در تقاطع توقف کنید"
      ],
      correctAnswer: 3,
      id: "IIGJQtn"
    },
    {
     question: "وقتی در هنگام رانندگی به جلو نگاه می کنید باید ",
      answers: [
        "اطراف خود را زیر نظر داشته باشید",
        "در تمام مدت به طور مستقیم نگاه کنید",
        "فقط به اتومبیل پیش روی خود نگاه کنید"
      ],
      correctAnswer: 1,
      id: "90DufOV"
    },
    {
     question: "شما در پشت چراغ قرمز منتظر پیچیدن به راست هستید. یک عابر پیاده در سمت راست وسیله شما قرار دارد که منتظر است از عرض خیابانی که شما قصد ورود به آن را دارید عبور کند. وقتی چراغ شما سبز می شود حق تقدم عبور با کیست",
      answers: [
        "چون چراغ شما سبز است حق تقدم عبور با شماست",
        "این فرد پیاده حق تقدم عبور دارد",
        "اگر خط عابر پیاده مشخص نباشد حق تقدم عبور با شماست"
      ],
      correctAnswer: 2,
      id: "kWIuZwR"
    },
    {
     question: "هنگام استفاده از نور بالا در شب هنگامی که در ۳۰۰ فوتی خودرویی که ـــــــــــ از نور پایین استفاده کنید",
      answers: [
        "قبلا از آن عبور کرده اید",
        "از پشت به آن نزدیک می شوید",
        "به شما از پشت نزدیک می شود"
      ],
      correctAnswer: 2,
      id: "6pgDTCr"
    },
    {
     question: "شما می خواهید در سرازیر در یک جاده دو طرفه پارک کنید و جدولی وجود ندارد. چراغ های جلو را باید در کدام سمت بچرخانید",
      answers: [
        "به صورت که مستقیم قرار بگیرند",
        "راست به سمت جاده ",
        "چپ به سمت وسط جاده "
      ],
      correctAnswer: null,
      id: "C_PLUQS"
    },
    {
     question: "می توانید در یک چراغ راهنمایی که قرمز است پس از بررسی برای عابرین پیاده و عبور و مرور، می توانید به سمت راست گردش کنید",
      answers: [
        "در هر زمان اگر سرعت خود را کاهش دهید و قبل از گردش چراغ چشمک زن بزنید",
        "اگر اول توقف کنید و اگر علامتی برای گردش به آن سمت را ممنوع نکرده باشد",
        "فقط اگر علامتی وجود داشته باشد که اجازه گردش بدهد"
      ],
      correctAnswer: 2,
      id: "KMDRzKC"
    },
    {
     question: "شما در کنار جاده پارک کرده اید و زمان طولانی برای ورود مجدد به ترافیک در حالیکه علامت می زنید، منتظر بوده اید. اما ترافیک سنگین است باید",
      answers: [
        "باز هم صبر کنید و حق تقدم را به ترافیک درون خط بدهید",
        "در شانه جاده رانندگی کنید تا فاصله ای در ترافیک ایجاد شود",
        "به آرامی خودرو خود را وارد خط ترافیک کنید"
      ],
      correctAnswer: 1,
      id: "__cVNwD"
    },
    {
     question: "اگر سن شما زیر ۱۸ سال باشد و در حین رانندگی تلفن همراه تان زنگ بزند شما باید",
      answers: [
        "پاسخ ندهید . بگذارید تا تلفون وارد حالت پست صوتی شود",
        "در صورتی که دستگاه هندزفری دارید، به تلفن پاسخ دهید",
        "در صورتی که تلفن از طرف والدین است به آن پاسخ دهید"
      ],
      correctAnswer: 1,
      id: "5qGbMbZ"
    },
    {
     question: "شما عموما باید در شرایط زیر آهسته و با دقت رانندگی کنید",
      answers: [
        "عبور کردن از کنار کامیون ها در بزرگراه ",
        "هنگامی که می خواهید به صحنه تصادف نگاه کنید",
        "نزدیک بودن به مدارس، زمین های بازی و مناطق مسکونی"
      ],
      correctAnswer: 3,
      id: "i7O-cwS"
    },
    {
     question: "کدامیک از این موارد در مورد رانندگی در پیچ که حد سرعت تعیین نشده است صحیح می باشد",
      answers: [
        "باید سرعت خود را بر اساس میزان تند بودن پیچ تغییر دهد",
        "باید سرعت خود را حفظ کرده تا از ترمز کردن در پیچ جلوگیری بعمل آورد",
        "تا آنجا که ممکن است در سمت چپ حرکت کند"
      ],
      correctAnswer: 1,
      id: "xmvZP3P"
    },
    {
     question: "فقط در شرایط زیر هنگامی که چراغ قرمز است می توانید به سمت چپ گردش کنید",
      answers: [
        "یک خیابان یک طرفه به خیابان یک طرفه",
        "یک خیابان دو طرفه به خیابان یک طرفه",
        "یک خیابان یک طرف به خیابان یک طرف"
      ],
      correctAnswer: 1,
      id: "nfqU72v"
    },
    {
     question: "تابلوی نارنجی، به شما هشدار می دهد که",
      answers: [
        "به منطقه یک مدرسه نزدیک می شوید",
        "به چراغ راهنمای نزدیک می شوید",
        "به محل کار کارگران با ماشین آلات جاده سازی نزدیک می شوید"
      ],
      correctAnswer: 3,
      id: "JMm0dIR"
    },
    {
     question: "اگر برای رانندگی بخاطر غلظت بالای الکل خون در حد قانونی یا بالان از آن محکوم شوید",
      answers: [
        "گواهینامه رانندگی شما به طور اتوماتیک بدون در نظر گرفتن وضعیت گواهینامه تجدید خواهد شد",
        "گواهینامه شما توسط اداره راهنمایی و رانندگی کالیفورنیا یا دادگاه معلق نخواهد شد ",
        "ممکن است به حداکثر شش ماه زندان و پرداخت جریمه محکوم شوید"
      ],
      correctAnswer: 2,
      id: "XVtmoim"
    },
    {
     question: "خط وسط خیابان دو طرفه برای مورد زیر استفاده می شود",
      answers: [
        "شروع یا پایان گردش به چپ یا شروع یوتورن مجاز",
        "عبور از خودرو دیگر هنگامی که ترافیکی از روبرو نمی آید",
        "عبور از خودرو دیگر هنگامی که ترافیکی از روبرو می آید"
      ],
      correctAnswer: 1,
      id: "y7WGkg7"
    },
    {
     question: "باید در موارد زیر با ارائه گزارش وقوع حادثه رانندگی کالیفورنیا موضوع را به سمت اس ار ۱ و اداره راهنمایی و رانندگی کالیفورنیا اطلاع دهید",
      answers: [
        "شما تصادف کرده باشید و در جریان آن، شخصی مجروح شده باشد",
        "شرکت خود را عوض می کنید",
        "شما تصادف کرده اید حتی اگر خسارتی وارد نشده است"
      ],
      correctAnswer: 1,
      id: "T0Yy9oA"
    },
    {
     question: "شما به چراغ راهنمایی که سبز است نزدیک می شوید اما از ترافیک تقاطع را مسدود کرده است باید",
      answers: [
        "تا زمانی که ترافیک رد شود وارد تقاطع نشوید",
        "وارد تقاطع شویدو منتظر شوید که تا راه بندان برطرف شود",
        "وارد خط دیگر شوید و سعی کنید ترافیک را رد کنید"
      ],
      correctAnswer: 1,
      id: "Ja-cEGy"
    },
    {
     question: "انداختن یا رها کردن یک حیوان در یک بزرگراه",
      answers: [
        "فقط در مناطق روستایی مجاز است",
        "می تواند منجر به پرداخت هزار دلار و شش ماه زندان گردد",
        "غیر قانونی نیست ولی بالقوه خطرناک است"
      ],
      correctAnswer: 2,
      id: "RfxJvwG"
    },
    {
     question: "کامیون های بزرگ که در خیابانی که دارای دو خط در هر طرف می باشد گردش می کنند",
      answers: [
        "اغلب باید از بخشی از خط سمت چپ برای گردش استفاده کند",
        "می توانند در خط راست یا خط چپ گردش خود را انجام دهند",
        "باید همیشه در راست باقی بمانند"
      ],
      correctAnswer: 1,
      id: "84EsI4b"
    },
    {
     question: "کدام یک از موارد زیر در مورد کیسه هوا درست است",
      answers: [
        "آنها در هر خودرویی ضروری می باشند ",
        "آنها شما را در مقایسه با استفاده تنها از کمربند ایمنی ایمن تر نگاه می کنند",
        "آنها می توانند به جای کمربند ایمنی استفاده شوند"
      ],
      correctAnswer: 2,
      id: "KuQUcXf"
    },
    {
     question: "در شرایط زیر می توانید در خط دوچرخه رانندگی کنید",
      answers: [
        "حداکثر ۲۰۰ فوت قبل از گردش به راست",
        "برای سبقت گرفتن از خودروی دیگر",
        "اگر آهسته تر از ۱۵ میل در ساعت حرکت می کنید"
      ],
      correctAnswer: 1,
      id: "EkKEgrA"
    },
    {
     question: "چه وقت تنها گذاشتن یک کودک شش ساله یا جوان تر در یک وسیله نقلیه مجاز است",
      answers: [
        "وقتی که یک کودک تحت مراقبت یک فرد ۱۲ ساله یا بزرگتر باشد",
        "وقتی که هوا گرم نیست",
        "وقتی که کودک کمتر از پنج دقیقه تنها گذاشته شود"
      ],
      correctAnswer: 1,
      id: "zod1LdR"
    },
    {
     question: "اگر در مشاهده خودروهای دیگر بخاطر گردو غبار یا دودی که در جاده است مشکل دارید باید سرعت خود را کاهش داده و ـــــــــــــــــــــــــــــــــــــــــــ را روشن کنید",
      answers: [
        "چراغ های پارک",
        "چراغ های علامت اضطراری – چهار چراغ چشمک زن",
        "چراغ های جلو"
      ],
      correctAnswer: 3,
      id: "DQEA-VE"
    },
    {
     question: "شما با یک آزمایش وجود در خون، نفس یا ادرار موافقت کرده اید",
      answers: [
        "هر وقت در کالیفورنیا رانندگی می کنید",
        "فقط وقتی که تصادف کنید",
        "اگر فقط الکل نوشیده باشید"
      ],
      correctAnswer: 1,
      id: "9sDI3bd"
    },
    {
     question: "هنگامی که اتوبوس مدرسه با چراغ های قرمت چشمک زن در جلوی شما در کنار جاده توقف کرده است باید",
      answers: [
        "توقف کرده تا همه کودکان اتوبوس را ترک کنند",
        "خط را عوض کنید، آهسته برانید و با احتیاط عبور کنید",
        "توقف کنید تا چشمک زدن چراغ ها توقف کند"
      ],
      correctAnswer: 3,
      id: "DgUOGoi"
    },
    {
     question: "وقتی از یک پیچ تند عبور می کنید",
      answers: [
        "باید زیر سرعت مجاز حرکت کنید",
        "در صورت لزوم به سرعت خود را برای توقف کم کنید",
        "اگر فکر می کنید که راننده خوبی هستید، می توانید از سرعت مجاز تجاوز کنید"
      ],
      correctAnswer: 2,
      id: "uculXv3"
    },
    {
     question: "دور زودن در مناطق تجاری،‌ جز در مواردی که در تابلوهای راهنمایی و رانندگی نشان داده شده باشد",
      answers: [
        "غیرمجاز است چون خطرناک هستند",
        "در تقاطع ها مجاز است مگر این که یک تابلو آن را ممنوع کرده باشد",
        "هر جا که وسایل نقلیه طرف مقابل خطری ایجاد نمی کند مجاز است"
      ],
      correctAnswer: 2,
      id: "o3ySNoR"
    },
    {
     question: "حرکت با فاصله کم پشت خودرو دیگر (تیل گیتینگ)",
      answers: [
        "روش خوبی برای نشان دادن نیت شما برای سبقت است",
        "فقط هنگامی ایمن است که از ترافیک با همان سرعت حرکت کند",
        "خطرناک است چون خودرو جلو شما ممکن است ناگهان ترمز کند"
      ],
      correctAnswer: 3,
      id: "-ZpBR6n"
    },
    {
     question: "شما در خط سریع (خط ۱) یک بزرگراه حرکت می کنید. ایمن ترین روش برای رفتن به خروجی بعد در سمت راست چیست",
      answers: [
        "خط را با قرار دادن اجباری خود در فاصله های کوچک تغییر دهید",
        "به سرعت از تمامی خطوط عبور کنید",
        "به نوبت و ایمنی از هر خط عبور کنید"
      ],
      correctAnswer: 3,
      id: "yKnnbX8"
    },
    {
     question: "هنگام پیوستن به آزاد راه باید ـــــــــــــــــــــــــــــــــــــــــــــ رانندگی کنید",
      answers: [
        "در حد یا نزدیکی سرعت ترافیک حرکت کنید",
        "آهسته تر از سرعت ترافیک حرکت کنید",
        "سریعتر از سرعت ترافیک حرکت کنید"
      ],
      correctAnswer: 1,
      id: "edlUaHZ"
    },
    {
     question: "وقتی در بزرگراه، خط خود را تغییر می دهید باید",
      answers: [
        "قبل از تغییر خط، چراغ راهنما بزنید",
        "همیشه قبل از اینکه شروع به تغییر خط کنید از سرعت خود کم کنید",
        "بدون زدن چراغ راهنما، تغییر خط دهید"
      ],
      correctAnswer: 1,
      id: "ZZbSt0f"
    },
    {
     question: "هنگام گردش به راست از یک خط گردش به راست، گردش خود را به خطر زیر تغییر دهید",
      answers: ["خط چپ", "نزدیک ترین خط به جدول", "هر خطی که موجود باشد"],
      correctAnswer: 2,
      id: "jiIqKeG"
    },
    {
     question: "علامت زرد به این معنی می باشد که",
      answers: [
        "بزرگراه در جلو تقسیم خواهد شد",
        "خط راست در جلو پایان می یابد",
        "فضای کمتر بین خطوط از جلو"
      ],
      correctAnswer: 2,
      id: "-vSDRzy"
    },
    {
     question: "در چه حالتی استفاده از تلفون همراه بدون داشتن هندزفری در هنگام رانندگی قانونی است",
      answers: [
        "هنگام استفاده از دستگاه برای بررسی پیام های متنی یا ایمیل",
        "هنگام تماس گرفتن برای دریافت کمک اضطراری ",
        "هنگام تلفون زدن در حالیکه پشت چراغ قرمز هستید"
      ],
      correctAnswer: 2,
      id: "dueriKk"
    },
    {
     question: "دنبال کردن سپر به سپر، پریدن راه سایر خودروها و مسدود کردن ترافیک نمونه ای از",
      answers: [
        "رانندگی پرخاشگرایانه است",
        "رانندگی بی ملاحظه است",
        "رانندگی دفاعی است"
      ],
      correctAnswer: 1,
      id: "qjHJ--k"
    },
    {
     question: "چنانچه ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــ باید اداره راهنمایی و رانندگی را ظرف ۱۰ روز با پر کردن فرم گزارش وقوع تصادف رانندگی در کالیفورنیا یا اس آر ۱ مطلع کنید",
      answers: [
        "تصادفی کرده باشید که در آن کسی مجروح شده باشد",
        "شرکت بیمه خود را عوض کرده باشید",
        "خود شما در آزمایش دود امتیاز لازم را کسب نکرد"
      ],
      correctAnswer: 1,
      id: "E483S3u"
    },
    {
     question: "چه وقت تنها گذاشتن یک کودک شش ساله یا جوان تر در یک وسیله نقلیه مجاز است",
      answers: [
        "وقتی که این کودک تحت مراقبت یک فرد ۱۲ ساله یا بزرگتر باشد",
        "وقتی که کودک کمتر از پنج دقیقه تنها گذاشته شود",
        "وقتی که چراغ های ماشین روشن است"
      ],
      correctAnswer: 1,
      id: "Bvo4LQf"
    },
    {
     question: "شما در منتهی الیه سمت راست یک آزاد راه چهار خطه رانندگی می کنید و متوجه خطوط ضخیم مقطع سفید در سمت چپ خط خود می شوید. شما در این خط رانندگی می کنید ",
      answers: [
        "خط کارپول و باید وارد خط بعد شوید",
        "یک خط ویژه برای خودروهایی که کند حرکت می کند",
        "یک خط خروج یا خطی که پایان خواهد یافت"
      ],
      correctAnswer: 3,
      id: "gTQnrIg"
    },
    {
     question: "هر راننده ای که هنگام دریافت علامت از جانب پلیس، خودرو را به کنار جاده هدایت نکند و عمدا از صحنه فرار کند حتی اگر کسی مجروح نشده باشد ممکن است به روش زیر مجازات شود",
      answers: [
        "احضاریه و اخطار",
        "جریمه تا هزار دلار",
        "حداکثر یک سال در زندان ایالتی "
      ],
      correctAnswer: 3,
      id: "EVJNeAA"
    },
    {
     question: "کدامیک از موارد زیر در حین رانندگی بر خلاف قانون می باشد",
      answers: [
        "استفاده ار کروز کنترول در خیابانهای مسکونی",
        "پوشیدن کلاخود با گوشی که هر دو گوش را بپوشاند",
        "پوشیدن کلاهخود یا گوشی که یک گوش را بپوشاند"
      ],
      correctAnswer: 3,
      id: "cyCJmSP"
    },
    {
     question: "کدام یک از موارد زیر در مورد موتورسیکلت سواران صحیح است",
      answers: [
        "آنها فقط می توانند بین دو خط ترافیک که حرکت نمی کند، حرکت نمایند",
        "آنها هرگز نمی توانند بین دو خط ترافیک حرکت کنند",
        "آنها می توانند بطور قانونی در فضای بین دو ترافیک حرکت کنند"
      ],
      correctAnswer: 3,
      id: "Z7LPrip"
    },
    {
     question: "دو خط توپر، دوبل زرد رنگ که به فاصله دو فوت یا بیشتر از هم قرار دارند به این معنی می باشد که",
      answers: [
        "می توانید فقط برای گردش به چپ یا دور برعکس آن از آنها عبور کنید",
        "ممکن است برای شروع یا پایان گردش به چپ استفاده شوند",
        "نباید آنها را قطع کرد و باید آنها را مانند یک دیوار فرض کرد"
      ],
      correctAnswer: 2,
      id: "VUPtiNP"
    },
    {
     question: "خطی که در آن حرکت می کنید در جنب یک خط دوچرخه قرار دارد. اگر قصد دارید در تقاطع بعد به سمت راست بپیچید شما",
      answers: [
        "نباید برای پیچیدن وارد خط دوچرخه شوید",
        "فقط وقتی می توانید وارد خط عبور دوچرخه شوید که قبل از پیچیدن، توقف کرده باشید",
        "باید قبل از پیچیدن، وارد خط دوچرخه شوید"
      ],
      correctAnswer: 3,
      id: "1NFp7bY"
    },
    {
     question: "شما یک تصادف کوچک کرده اید. هیچ کس صدمه ندیده است و خسارت بسیار اندک است. باید",
      answers: [
        "خودرو را در خط ترافیک خود ترک کنید تا نیروهای انتظامی به محل برسند",
        "خودرو خود را به هر دلیل حرکت ندهید",
        "خودرو خود را به نزدیک ترین مکان ترافیک را مسدود نکند حرکت دهید"
      ],
      correctAnswer: 3,
      id: "65dWV-W"
    },
    {
     question: "هنگامی که یک پیچ تند در جاده قرار دارد",
      answers: [
        "سرعت خود را حفظ کنید تا از پشت سر به شما برخورد نکنند",
        "قبل از ورود به پیچ ترمز کردن را آغاز کنید",
        "سرعت خود را در پیچ افزایش دهید"
      ],
      correctAnswer: 2,
      id: "-QOnkyb"
    },
    {
     question: "گردش در جا در مناطق مسکونی در وضعیت زیر قانونی است",
      answers: [
        "هنگامی که هیچ خودرویی به شما نزدیک نمی باشد",
        "در طول دو خط توپر، دوبل زرد رنگ که به فاصله دو فوت یا بیشتر از قرار دارند",
        "در یک خیابان یک طرفه با دیدن چراغ سبر پیکان جهت دار"
      ],
      correctAnswer: 2,
      id: "avUyFkH"
    },
    {
     question: "کدام یک از موارد زیر در مورد کمربندهای ایمنی درست است",
      answers: [
        "اگر وسیله نقلیه شما، مجهز به کیسه های هوای جلو و عقب است دیگر نیازی به کمربند ایمنی ندارید",
        "در سفرهای طولانی، مهم تر از سفرهای کوتاه بین شهری است",
        "احتمال زنده ماندن شما را در اغلب تصادفات افزایش می دهد"
      ],
      correctAnswer: 3,
      id: "OUang0R"
    },
    {
     question: "یک اتومبیل ناگهان جلوی شما پیچیده و ایجاد خطر می کند. شما باید کدام یک از اقدامات زیر را انجام دهید",
      answers: [
        "بوق بزنید و به خط کاری منحرف نشوید",
        "پای خود را از روی پدال گاز بردارید",
        "با نور بالا چراغ دهید"
      ],
      correctAnswer: 2,
      id: "gnIuGNs"
    },
    {
     question: "برای کمک به هشیار بودن در مسیرهای طولانی",
      answers: [
        "به طور منظم برای استراحت توقف کنید حتی اگر خسته نیستید",
        "پنجره ها را بالا بکشید و به جلو خیره شوید",
        "بخاری را روشن کنید"
      ],
      correctAnswer: 1,
      id: "rcerTkq"
    },
    {
     question: "وقتی در سربالایی پارک می کنید، همیشه ترمز دستی خود را بکشید و ",
      answers: [
        "اتومبیل خود را در حالت خلاص بگذارید",
        "اطمینان حاصل کنید که وسیله نقلیه شما در حالت پارک یا در دنده است",
        "چرخ های وسیله نقلیه خود را موازی با جاده قرار دهید"
      ],
      correctAnswer: 3,
      id: "jw_E3IC"
    },
    {
     question: "حرکت با فاصله کم پشت خودرو دیگر (تیل گیتینگ)",
      answers: [
        "روش خوبی برای نشان دادن نیت شما برای سبقت است",
        "خطرناک است چون خودرو جلو شما ممکن است ناگهان ترمز کند",
        "فقط هنگامی ایمن است که ترافیک با همان سرعت حرکت کند"
      ],
      correctAnswer: 2,
      id: "h4G96fl"
    },
    {
     question: "هر راننده ای هنگام دریافت از جانب پلیس، خودرو خود را به کنار جاده هدایت نکند و عمدا از صحنه فرار کند حتی اگر کسی مجروح نشده باشد، ممکن است به روش زیر مجازات شود",
      answers: [
        "جریمه تا حد هزار دلار ",
        "احضاریه و اخطار",
        "حداکثر یک سال در زندان ایالتی"
      ],
      correctAnswer: 3,
      id: "Ncy9_Ap"
    },
    {
     question: "اگر یک راننده حواس پرت را در حال صحبت با تلفون همراه دیدید باید",
      answers: [
        "به آنها علامت بدهید که از موبایل خود استفاده نکند",
        "به پلیس اطلاع دهید",
        "فاصله بیشتری بین وسیله نقلیه خود و خودروی او ایجاد کنید"
      ],
      correctAnswer: 3,
      id: "X-LfsTq"
    },
    {
     question: "چراغ قرمز چشمک زن در تقاطع به معنی",
      answers: [
        "کاهش سرعت قبل از ورود به تقاطع می باشد",
        "قبل از ورود توقف کنید",
        "توقف کنید و منتظر چراغ سبز شوید"
      ],
      correctAnswer: 3,
      id: "J6MdHx8"
    },
    {
     question: "هنگامی که خودروی دیگر را هنگام رانندگی نمی بینید",
      answers: [
        "لزومی ندارد برای گردش کردن و تغییر خط علامت راهنما بزنید",
        "باید باز هم برای گردش کردن و تغییر خط علامت راهنما را بزنید",
        "می توانید قانونا از سرعت تعیین شده سریع تر حرکت کنید"
      ],
      correctAnswer: 2,
      id: "MQqwzDr"
    },
    {
     question: "اگر راننده پشت سر شما بخواهد سریع تر از شما حرکت کند شما باید ",
      answers: [
        "سرعت خود را کاهش دهید و یک ترمز سبک بگیرید",
        "سرعت خود را افزایش دهید",
        "در صورت امکان به دست راست حرکت کنید"
      ],
      correctAnswer: 3,
      id: "rYKtaaG"
    },
    {
     question: "در چه حالتی استفاده از تلفون همراه بدون داشتن هندزفری، هنگام رانندگی قانونی است",
      answers: [
        "هنگام تماس گرفتن برای دریافت کمک اضطراری",
        "هنگام استفاده از دستگاه برای بررسی پیام های متنی یا ایمیل",
        "هنگام تلفون زدن در حالیکه پشت چراغ قرمز ایستاده اید"
      ],
      correctAnswer: 1,
      id: "G42WMPk"
    },
    {
     question: "وقتی در هنگام رانندگی به جلو نگاه می کنید باید",
      answers: [
        "فقط به اتومبیل پیش روی خود نگاه کنید",
        "در تمام مدت به طور مستقیم نگاه کنید",
        "اطراف خود را در نظر داشته باشید "
      ],
      correctAnswer: 3,
      id: "OZFBFRr"
    },
    {
     question: "هنگام باران جاده ها ــــــــــــــــــــــــــــــــــــــــــــــــــــــــــــ در لغزنده ترین حالت قرار دارند",
      answers: [
        "هنگامی که برای چند ساعت باران باریده باشد",
        "پس از توقف باران",
        "در چند دقیقه اول"
      ],
      correctAnswer: 3,
      id: "wKmyyG8"
    },
    {
     question: "اگر کندتر از جریان ترافیک در یک آزادراه حرکت کنید شما",
      answers: [
        "باید در خط منتهی الیه سمت چپ (خط ۱) حرکت کنید ",
        "یک تکنیک رانندگی دفاعی از خود نشان دهید",
        "ممکن است بخاطر مسدود کردن حرکت ترافیک احضاریه بگیرید"
      ],
      correctAnswer: 3,
      id: "2W8Sfpd"
    },
    {
     question: "باد وجود دارد و خودرو جلو شما اغلب سرعت خود را بدون دلیل کاهش می دهد. در این موقعیت باید",
      answers: [
        "فاصله خود را با خودروی دیگر افزایش دهید",
        "در شانه جاده حرکت کنید تا از خودرو عبور کنید",
        "با چراغ های جلو به سرعت علامت دهید تا راننده دیگر را آگاه کنید"
      ],
      correctAnswer: 1,
      id: "sEAKPWp"
    },
    {
     question: "هنگام رانندگی چه موقع باید جلو خود را بررسی کنید",
      answers: [
        "فقط برای دیدن زود هنگام خطرات احتمالی",
        "فقط در مناطق مسکونی",
        "عمدتا بر وسط جاده تمرکز کنید"
      ],
      correctAnswer: null,
      id: "j6d_ARj"
    },
    {
     question: "در آزاد راه، شما باید جاده را اسکن کرده و ",
      answers: [
        "مراقب سیگنال های رانندگان دیگر باشید",
        "دائما به جاده ای که درست جلوی خودرو شما می باشد نگاه کنید",
        "آینه های خود را کمتر نگاه کنید"
      ],
      correctAnswer: 1,
      id: "VkNkfKX"
    },
    {
     question: "شما خودرویی که از پشت به شما نزدیک می شود را می بینید. هنگامی که آینه عقب را دوباره برای تغییر خط بررسی می کنید، دیگر خود را نمی بینید",
      answers: [
        "از روی شانه خود نگاه می کنید که خودرو در نقطه کور شما قرار نداشته باشد",
        "چراغ راهنما خود را روشن کرده و به آرامی خط خود را تغییر دهید",
        "آینه بیرون را بررسی کنید تا ببینید خودرو دیگری در نقطه کور شما قرار نداشته باشد"
      ],
      correctAnswer: 1,
      id: "KOAeld-"
    },
    {
     question: "عابرین پیاده در پیچ ها حق تقدم عبور دارند",
      answers: [
        "چه خط عابر پیاده کشیده شده باشد یا کشیده نشده باشد",
        "فقط در تقاطع های کنترل شده",
        "فقط هنگامی که خط عابر پیاده در خیابان کشیده شده باشد"
      ],
      correctAnswer: 1,
      id: "TkeEbhY"
    },
    {
     question: "یک اتومبیل ناگهان جلوی شما می پیچد و ایجاد خطر می کند. شما باید کدام یک از اقدامات زیر را انجام دهید",
      answers: [
        "با نور بالا چراغ دهید",
        "پای خود را از روی پدال گاز بر می دارید",
        "بوق بزنید و به خط کناری منحرف شوید"
      ],
      correctAnswer: 2,
      id: "0pSTLwP"
    },
    {
     question: "کدام یک از داروهای زیر احتمالا می تواند به توانایی شما در رانندگی لطمه بزند",
      answers: [
        "هر نوع دارویی",
        "فقط داروهای سرماخوردگی و حساسیت",
        "فقط داروهای نسخه دار"
      ],
      correctAnswer: 1,
      id: "NV1McKz"
    },
    {
     question: "رانندگی با فصله بیش از حد نزدیک به سپر عقب خودروی دیگر (تیل گیتینگ)",
      answers: [
        "می تواند باعث نگرانی رانندگان دیگر شده و آنان را عصبانی کند",
        "نمی تواند باعث گرفتن احضاریه ترافیکی شود",
        "تصادفات را با کاهش بریدن مسیر خودروهای دیگر، کاهش می دهد"
      ],
      correctAnswer: 1,
      id: "SSkH5-8"
    },
    {
     question: "شما در حال رانندگی با خودرویی که پارک شده است تصادف می کنید و نمی توانید مالک خودرو را پیدا کنید. چکار باید انجام بدهید",
      answers: [
        "به دنبال مالک یا راننده بگردید و اگر آنها را پیدا نکردید محل را ترک کنید",
        "یادداشتی حاوی نام، شماره تلفون و نشانی خود را در خودروی پارک شده بگذارید",
        "منتظر مالک یا راننده شوید تا به محل برگردد"
      ],
      correctAnswer: 2,
      id: "owdkhXj"
    },
    {
     question: "کدام یک از حملات زیر در مورد کامیون های تجاری بزرگ صحیح است",
      answers: [
        "آنها دارای ترمزهای بسیار قوی می باشند که به آنان امکان می دهد به سرعت متوقف شوند",
        "آنها قدرت مانور بیشتر نسبت به خودروهای مسافری دارند",
        "زمان توقف آنان طولانی تر از خودروهای مسافری می باشند"
      ],
      correctAnswer: 3,
      id: "2kkNe7D"
    },
    {
     question: "می توانید در حالت زیر به صورت قانونی هنگامی که چراغ قرمز است گردش کنید",
      answers: [
        "فقط پس از کاهش سرعت و بررسی کردن ترافیک ",
        "فقط پس از توقف مگر اینکه ممنوع شده باشد",
        "تحت هیچ شرایط عبور نمی توانید "
      ],
      correctAnswer: 2,
      id: "Eq_4DtS"
    },
    {
     question: "شما در بزرگراه رانندگی می کنید و یک خودرو گشت که در جلوی شما قرار دارد چرا غ های اضطراری عقب خود را روشن می کند (چراغ های هشدار خطر) و در کلیه خطوط به صورت موجی رانندگی می کند. باید",
      answers: [
        "فاصله مطمئن را با خودرو گشت حفظ کنید",
        "خودرو خود را به سمت راست جاده منحرف کنید",
        "همیشه کاملا توقف کنید"
      ],
      correctAnswer: 1,
      id: "EG3K8D7"
    },
    {
     question: "اگر راننده ای تصادف کرده باشد طبق قانون، وی موظف به تبادل اطلاعات مربوط به گواهینامه رانندگی با چه کسانی می باشد",
      answers: [
        "نیروی انتظامی ",
        "تمامی کسانی که در حادثه تصادف بوده اند",
        "شاهدین"
      ],
      correctAnswer: 2,
      id: "5gzME3s"
    },
    {
     question: "قبل از اینکه از طرف ترافیک خیابان اتومبیل پارک شده خود خارج شوید باید",
      answers: [
        "مواظب وسایل نقلیه، دوچرخه سواران و موتورسیکلت سواران باشید",
        "راهنمای دست چپ خود را بزنید",
        "با دست علامت بدهید که قصد خروج از اتومبیل خود را دارید "
      ],
      correctAnswer: 1,
      id: "V5y7X39"
    },
    {
     question: "در صورتی می توانید با کمترین خطر، وارد مسیری شوید که ",
      answers: [
        "با فشار خود را وارد یک فاصله کوچک کنید",
        "تا وقتی که محلی را برای ورود به جاده پیدا کنید در شانه جاده حرکت کنید",
        "برای ورود به مسیر، صبر کنید تا فاصله زیادی در مسیر ایجاد شود"
      ],
      correctAnswer: null,
      id: "TbwQmdm"
    },
    {
     question: "هنگام نزدیک شدن به تقاطعی که علامت توقف یا (راه دهید) می باشد باید همیشه",
      answers: [
        "حق تقدم را به ترافیک سمت راست خود بدهید",
        "با تقاطع به نحوی که دارای علامت توقف بوده است برخورد کنید",
        "به ترافیک و عابرین پیاده که از قبل در تقاطع هستند، حق تقدم بدهید"
      ],
      correctAnswer: 3,
      id: "Yg3VnE9"
    },
    {
     question: "شب هنگام است. خودرویی به طرف شما می آید که نور بالای آن روشن است و باعث می شود دیدن جاده برای شما دشوار شود",
      answers: [
        "به سمت جلو و لبه راست خود توجه کنید",
        "مستقیم به خط خود نگاه کنید",
        "به سمت جلو و لبه چپ خط خود توجه کنید"
      ],
      correctAnswer: 1,
      id: "cLK9E9q"
    },
    {
     question: "شما برای گردش به سمت راست آماده می شوید. باید",
      answers: [
        "سرعت خود را کاهش داده و هنگام شروع گردش چراغ راهنمای خود را روشن کنید",
        "همیشه قبل از گردش به راست توقف کنید",
        "در ۱۰۰ فوت اخر قبل از گردش از چراغ راهنما استفاده کنید"
      ],
      correctAnswer: 3,
      id: "DbqCWSl"
    },
    {
     question: "اگر راننده ای هستید که تصادف کرده اید، باید با افراد دیگر اطلاعات مربوط به گواهینامه رانندگی خود و ـــــــــــــــــــــــــ را مبادله کنید",
      answers: [
        "فقط مدرک بیمه و مدارک ثبت خودرو",
        "فقط مدرک بیمه",
        "مدرک بیمه، مدرک ثبت خودرو و آدرس اصلی خود "
      ],
      correctAnswer: null,
      id: "NNwx93A"
    },
    {
     question: "اگر جاده خیس است و خودروها شروع به لغزیدن می کنند باید",
      answers: [
        "به آرامی پای خود را از روی پدال گاز بردارید",
        "سرعت خود را با پمپ کردن سریع و محکم ترمزها کاهش دهید",
        "پال گاز را فشار دهید تا از جاده لغزنده با سرعت بیشتر عبور کنید"
      ],
      correctAnswer: 1,
      id: "V6497fa"
    },
    {
     question: "شما برای گردش به راست آماده می شوید. باید",
      answers: [
        "در ۱۰۰ فوت آخر قبل از گردش از چراغ راهنما استفاده کنید",
        "همیشه قبل از گردش به راست توقف کنید",
        "سرعت خود را کاهش داده و هنگام شروع گردش چراغ راهنمای خود را روشن کنید"
      ],
      correctAnswer: 3,
      id: "PptvR44"
    },
    {
     question: "دور زدن در مناطق تجاری، جز در مواردی که در تابلوهای راهنمای و رانندگی نشان داده شده باشد",
      answers: [
        "غیر مجاز است چون خطرناک است",
        "در تقاطع ها مجاز است، مگر این که یک تابلو آن را ممنوع کرده باشد",
        "هر جا که وسیله نقلیه طرف مقابل خطری ایجاد نمی کند مجاز است"
      ],
      correctAnswer: 2,
      id: "Tam1m4v"
    },
    {
     question: "حد سرعت برای منطقه مدرسه در زمانی که دانش آموزان در محل باشند ــــــــــــــــــــــــــــــــــــــــــــــــــــ می باشد مگر اینکه سرعت دیگری تعیین شده باشد",
      answers: ["۳۰ مایل در ساعت", "۲۵ مایل در ساعت", "۱۵ مایل در ساعت"],
      correctAnswer: null,
      id: "4utI1nY"
    },
    {
     question: "در صورتی می توانید با کمترین خطر، وارد مسیری شوید که ",
      answers: [
        "تا وقتی که محلی را برای ورود به جاده پیدا کنید در شانه جاده حرکت کنید",
        "برای ورود به مسیر، صبر کنید تا فاصله زیادی در مسیر تردد ایجاد شود",
        "با فشار خود را وارد یک فاصله کوچک کنید"
      ],
      correctAnswer: null,
      id: "XwzjQZM"
    },
    {
     question: "اگر هنگام رانندگی، به علت وجود مه غلیظ نمی توانید جاده را خوب ببینید باید",
      answers: [
        "کاملا در کنار جاده پارک نموده و چراغ های اضطراری ( چراغ های اعلام خطر ) خود را روشن کنید",
        "چراغ های نور بالا را روشن نموده و به رانندگی ادامه دهید ",
        "چراغ های حالت اضطراری (چراغ های اعلام خطر) را روشن نموده و به رانندگی ادامه دهید"
      ],
      correctAnswer: 2,
      id: "BP9-Cwy"
    },
    {
     question: "هنگام پیوستن به آزادراه باید ـــــــــــــــــــــــــــــــــــــــــــ رانندگی کنید",
      answers: [
        "در حد یا نزدیکی سرعت ترافیک حرکت کنید",
        "سریعتر از سرعت ترافیک حرکت کنید",
        "آهسته تر از سرعت ترافیک حرکت کنید"
      ],
      correctAnswer: 1,
      id: "VHdSVGk"
    },
    {
     question: "کدام یک از جملات زیر در مورد دارو و رانندگی صحیح است",
      answers: [
        "هر داروی تجویزی را در صورتی که احساس سرگیجه داشته باشید می توانید مصرف کنید",
        "همه داروها، نسخه ها یا داروی بدون نسخه ممکن است بر رانندگی شما تاثیر بگذارد",
        "فقط داروهای غیر قانونی ممکن است رانندگی را مختل کند"
      ],
      correctAnswer: 2,
      id: "lpVresZ"
    },
    {
     question: "هنگام رانندگی چه موقع باید جلو خود را بررسی کنید",
      answers: [
        "فقط برای دیدن زود هنگام خطرات احتمالی ",
        "فقط در مناطق مسکونی",
        "عمدتا بر وسط جاده تمرکز کنید"
      ],
      correctAnswer: 1,
      id: "itFg_Y4"
    },
    {
     question: "شما به چراغ راهنمایی که سبز است نزدیک می شوید، اما ترافیک تقاطع را مسدود کرده است. بهترین کاری که می شود انجام داد چیست؟ ",
      answers: [
        "وارد تقاطع شوید و منتظر شوید تا راه بندان برطرف شود",
        "وارد تقاطع نشوید تا بتوانید کامل از تقاطع عبور کنید",
        "تا حدی وارد تقاطع شوید و حق عبور خود را ایجاد کنید"
      ],
      correctAnswer: 2,
      id: "kvNiQr9"
    },
    {
     question: "قانون عمومی سرعت کالیفورنیا می گوید باید",
      answers: [
        "هرگز سریعتر از آنچه برای شرایط کنونی مناسب است حرکت نکنید",
        "سرعت خود را در حد سرعت ترافیک در حال حرکت نگاه دارید",
        "همیشه بر اساس سرعت اعلام شده رانندگی کنید"
      ],
      correctAnswer: 1,
      id: "xmZSnTF"
    },
    {
     question: "مسئولیت اطلاع از چگونگی تاثیر دارو بر رانندگی شما،‌بر عهده چه کسی است",
      answers: ["فقط دارو ساز شما", "فقط پزشک شما", "خود شما "],
      correctAnswer: 3,
      id: "qAnXpMo"
    },
    {
     question: "اگر برای رانندگی بخاطر غلظت بالای الکل خود در حد قانونی یا بالاتر از آن دستگیر یا بازداشت شوید",
      answers: [
        "اداره راهنمایی و رانندگی ممکن است بر علیه امتیاز رانندگی شما اقدام اداری انجام دهد",
        "ممکن است به شما گفته شود که امتحان عملی رانندگی را انجام دهید",
        "ممکن از شما خواسته شود تا گواهینامه رانندگی خود را تجدید کنید"
      ],
      correctAnswer: 1,
      id: "I_7L7YM"
    },
    {
     question: "برای گردش به چپ از یک خیابان یک طرفه چند خطه به یک خیابان دو طرفه گردش را از این سمت شروع کنید",
      answers: [
        "خط منتهی الیه سمت چپ",
        "هر خطی که موجود باشد",
        "نزدیک ترین خط به وسط جاده"
      ],
      correctAnswer: 1,
      id: "GyW0FP8"
    },
    {
     question: "هنگام نزدیک شدن به یک پیچ، باید استفاده از ترمزهای خود برای کاهش سرعت خودرو را آغاز کنید",
      answers: [
        "قبل از ورود به پیچ",
        "درست پس از ورود به پیچ",
        "هنگام خروج از پیچ"
      ],
      correctAnswer: null,
      id: "mJdkDNB"
    },
    {
     question: "شما با سرعت ۵۵ مایل در ساعت در منتهی الیه سمت چپ (خط سریع) یک آزاپ راه که حد سرعت آن ۶۵ مایل در ساعت است حرکت می کنید. ممکن است بخاطر رانندگی بیش از حد کند جریمه شوید",
      answers: [
        "اگر جاده با شرایط آب و هوایی شما را وادار به رانندگی با سرعت کم کند",
        "تحت هیچ شرایط چون رانندگی با سرعت کم نسبت به ترافیک همیشه ایمن تر است",
        "اگر شما جریان عادی معقول ترافیک را مسدود کرده باشید "
      ],
      correctAnswer: 3,
      id: "arCwecr"
    },
    {
     question: "هنگام نزدیک شدن به یک خط عابر پیاده کنترول نشده که یک عابر نابینا منتظر عبور از خیابان است باید توقف کنید",
      answers: [
        "در محل خط عابر پیاده توقف کنید و صبر کنید تا عابر پیاده از خیابان عبور کند",
        "چند فوت قبل از خط عابر پیاده توقف کنید تا صدای موتور شما به گوش عابر پیاده برسد",
        "در محل خط عابر پیاده توقف کنید و به عابر پیاده بگوید که از خیابان عبور کند"
      ],
      correctAnswer: 1,
      id: "mN7EBbv"
    },
    {
     question: "شما در آزاپراه در حال کشیدن تریلر هستید. با چه سرعتی می توانید در شرایط ایده آل حرکت کنید",
      answers: ["۵۵ مایل در ساعت ", "۶۵ مایل در ساعت", "۴۵ مایل در ساعت"],
      correctAnswer: 2,
      id: "QXXdbNT"
    },
    {
     question: "بار که بیشتر از چهار فوت از سپر عقب بیرون زده باشد",
      answers: [
        "قانونا لازم نیست علامت گذاری شود اما انجام این کار ایده خوبی محسوب می شود",
        "در همه شرایط غیر قانونی است",
        "باید یک پرچم یا چراغ قرمز علامت گذاری شود"
      ],
      correctAnswer: 3,
      id: "Rs_UJ3J"
    },
    {
     question: "چنانچه ـــــــــــــــــــــــــــــــــــــــــــــــــــ باید اداره راهنمایی و رانندگی را ظرف ۱۰ روز با پر کردن فرم گزارش وقوع تصادف رانندگی در کالیفورنیا مطلع سازید",
      answers: [
        "شرکت بیمه خود را عوض کرده باشید",
        "تصادفی کرده باشید که در آن کسی مجروح نشده باشد",
        "خودرو شما در آزمایش دود امتیاز لازم را کسب نکرد"
      ],
      correctAnswer: 2,
      id: "p11yY7N"
    },
    {
     question: "وقتی که وسیله نقلیه، در حال حرکت است، شما ملزم به بستن کمربند ایمنی هستید",
      answers: [
        "و در صورت هر گونه کوتاهی، ممکن است جریمه شوید",
        "فقط برای وسایل نقلیه ای که پس از سال ۱۹۷۵ تولید شده اند",
        "مگر این که در پشت وانت / کاروان سوار شده باشید"
      ],
      correctAnswer: 1,
      id: "HzCRBmo"
    },
    {
     question: "رانندگان موظف یه پیروی از دستورات از منابع زیر هستند",
      answers: [
        "وسایل مکان یابی (جی پی اس) اگر در وسیله نقلیه نصب شده باشند",
        "سایر وسایل نقلیه که وسیله آنها در راه خراب شده است",
        "کارگرانی (پرچم داران) که ترافیک را در مناطق جاده سازی راهنمایی می کنند"
      ],
      correctAnswer: 3,
      id: "eF5M7uE"
    },
    {
     question: "در روزهای یخبندان و خیس کدام یک از مسیرها احتمالا دارای نقاط یخ زده می باشند",
      answers: ["پل ها و رورگذرها", "تقاطع ها", "جاده های اسفالت شده"],
      correctAnswer: 1,
      id: "i-3h4IH"
    },
    {
     question: "هنگامی که جاده بخاطر باران خیس شده است و شما پشت سر خودرو دیگر حرکت می کنید باید",
      answers: [
        "فاصله خودرو خود با خودرو دیگر را افزایش دهید",
        "دائما به جاده ای که درست جلوی خودرو شما می باشد نگاه کنید",
        "فاصله خودرو خود را بخودرو جلو را کاهش دهید"
      ],
      correctAnswer: 1,
      id: "YWmEK4b"
    },
    {
     question: "چراغ قرمز چشمک زن در تقاطع به معنی",
      answers: [
        "توقف کنید و منتظر چراغ سبز باشید",
        "قبل از ورود توقف کنید",
        "کاهش سرعت قبل از ورود به تقاطع می باشد"
      ],
      correctAnswer: 2,
      id: "ilbGTQ3"
    },
    {
     question: "چه موقع باید وارد خط دوچرخه شوید",
      answers: [
        "تحت هیچ شرایط عبور نمی کنید",
        "برای گردش به راست",
        "برای سبقت گرفتن از خودرو دیگر"
      ],
      correctAnswer: 2,
      id: "bY6VlPP"
    },
    {
     question: "هنگامی که شرایط آب و هوایی شما را ملزم به استفاده ممتد از برف پاک کن می کند باید ـــــــــــــــــــــــــــــــــــــــــــــــــــــــــ هم استفاده کنید",
      answers: [
        "چراغ های جلو",
        "بخاری شیشه",
        "چراغ های علامت اضطراری (چهار چراغ چشمک زن) "
      ],
      correctAnswer: 1,
      id: "Kwb-5PQ"
    },
    {
     question: "اگر یک فرد پیاده روی خط کشی عابر است",
      answers: [
        "این فرد پیاده حق تقدم عبور دارد",
        "این فرد پیاده باید رعایت حق تقدم دیگران را بنماید",
        "وسایل نقلیه حق تقدم عبور دارند اما رانندگان باید قانونا مواظب ایمنی افراد پیاده باشند"
      ],
      correctAnswer: 1,
      id: "s66toRO"
    },
    {
     question: "در روزهای گرم، تنها گذاشتن کودکان شش ساله یا کوچکتر در خودرو غیر قانونی است مگر",
      answers: [
        "همیشه ",
        "وقتی که این کودک تحت مراقبت یک فرد 12 ساله یا بزرگتر باشد",
        "فقط زمانی که سوئیچ روی خودرو است"
      ],
      correctAnswer: 2,
      id: "NM62dDJ"
    },
    {
     question: "شما در حال رانندگی با خودرویی که پارک شده است تصادف می کنید و نمی توانید مالک خودرو را پیدا کنید. چکار باید انجام دهید",
      answers: [
        "به دنبال مالک یا راننده بگردید. اگر آنها را پیدا نکردید محل را ترک کنید",
        "یادداشتی حاوی نام، شماره تلفون و نشانی خود را در خودروی پارک شده بگذارید",
        "منتظر مالک یا راننده شوید تا به محل بازگردد"
      ],
      correctAnswer: 2,
      id: "yIYrvLx"
    },
    {
     question: "می توانید در حالت زیر به صورت قانونی هنگامی که چراغ قرمز است گردش کنید",
      answers: [
        "فقط پس از کاهش سرعت و بررسی کردن ترافیک ",
        "فقط پس از توقف مگر اینکه ممنوع شده باشد",
        "تحت هیچ شرایط عبور نمی کنید"
      ],
      correctAnswer: 2,
      id: "FzmtIpf"
    },
    {
     question: "کم کردن سرعت برای نگاه کردن دقیق به موارد خطرناک حواس پرتی ممکن است ",
      answers: [
        "از بروز تصادفات پشت سر جلوگیری بعمل آورد",
        "راه بندان ترافیکی ایجاد کند",
        "جریان ترافیک را بهبود ببخشد"
      ],
      correctAnswer: 2,
      id: "u61BtsU"
    },
    {
     question: "دستورات نگهبانان مدرسه باید پیروی شوند",
      answers: [
        "فقط در طول  ساعات مدرسه",
        "همیشه در تمامی اوقات",
        "فقط هنگامی که کودکان در جلوی مدرسه باشند"
      ],
      correctAnswer: 2,
      id: "XSc7tZK"
    },
    {
     question: "در آزاد راه، شما باید جاده را اسکن کرده و ",
      answers: [
        "مراقب سیگنال های رانندگان دیگر باشید",
        "دائما به جاده ای درست جلوی خودروی شما می باشد نگاه کنید",
        "آینه های خود را کمتر نگاه کنید"
      ],
      correctAnswer: 1,
      id: "i9U0lxr"
    },
    {
     question: "اگر یک تریلر را در جهت حرکت خود در یک بزرگراه چهار خطه یدک می کشید، شما می توانید در مسیرهای زیر حرکت کنید",
      answers: [
        "هر یک از خطوط تردد در جهت حرکت تان",
        "هر کدام از دو خط سمت راست خود",
        "فقط دورترین خط راست خود"
      ],
      correctAnswer: 2,
      id: "XRxi8I_"
    },
    {
     question: "یک چراغ شمارش معکوس در چراغ عابر پیاده به چه معنی است",
      answers: [
        "تعداد ثانیه هایی که خودروها باید برای عبور از تقاطع صبر کند را نشان می دهد",
        "تعداد ثانیه هایی که عابرین باید قبل از عبور از خیابان صبر کنند را نشان می دهد",
        "تعداد ثانیه هایی که عابرین پیاده باید طی آن از خایابان را عبور کنند را نشان می دهد"
      ],
      correctAnswer: 3,
      id: "XoIIgVS"
    },
    {
     question: "چه موقع می توانید در کنار جدولی که رنگ آن قرمز است پارک کنید",
      answers: [
        "برای سوار کردن یا پیاده کردن مسافران ",
        "تحت هیچ شرایط",
        "هنگامی که قصد دارید در خودرو خود بمانید"
      ],
      correctAnswer: 2,
      id: "8fwu175"
    },
    {
     question: "اگر یک فرد پیاده روی خط کشی عابر است",
      answers: [
        "وسایل نقلیه حق تقدم عبور دارند اما رانندگان باید قانونا مواظب ایمنی افراد پیاده باشند",
        "این فرد پیاده باید رعایت حق تقدم دیگران را بنماید",
        "این فرد پیاده حق تقدم عبور دارد"
      ],
      correctAnswer: 3,
      id: "MsF4Q4n"
    },
    {
     question: "وقتی در شب، از نور بالا استفاده می کنید در فاصله 500 متری از کدامیک از وسایط نقلیه باید آن را به نور پایین تبدیل کنید",
      answers: [
        "وسیله نقلیه ای که از آن سبقت گرفته اید",
        "وسیله نقلیه ای که از روبروی در حال آمدن است",
        "وسیله نقلیه ای که از پشت سر شما در حال آمدن است"
      ],
      correctAnswer: 2,
      id: "Ri8BaDt"
    },
    {
     question: "کدام یک از این خودروها باید قبل از تقاطع راه آهن توقف کنند",
      answers: [
        "تانکرهای دارای علامت حاوی مواد خطرناک",
        "خودروهای اسپورت که حامل چهار یا تعداد بیشتر مسافر هستند",
        "خانه های خودرویی یا وانت هایی که یک تریلر را به دنبال خود می کشند"
      ],
      correctAnswer: 1,
      id: "i-6qqtW"
    },
    {
     question: "شما در یک جاده دو طرفه هستید و خودرو جلو شما به سمت چپ برای ورود به یک دراو وی گردش می کند می توانید قانونا از خودرویی از سمت راست سبقت بگیرید",
      answers: [
        "حتی اگر لازم باشد که در شانه جاده رانندگی کنید",
        "اگر بتوانید این کا را بدون اینکه در شانه جاده رانندگی کنید انجام دهید",
        "تحت هیچ شرایطی عبور نمی کنید"
      ],
      correctAnswer: 3,
      id: "UdIjmpb"
    },
    {
     question: "احتمال بروز تصادف هنگامی که ___________________ بیشتر است",
      answers: [
        "همه خودروها در یک سرعت یا سرعت نسبتا مشابه حرکت می کنند",
        "یک خودرو سریع تر یا آهسته تر از جریان ترافیک حرکت می کند",
        "رانندگان حواس شان پرت شده است"
      ],
      correctAnswer: 2,
      id: "N7kDMY5"
    },
    {
     question: "وقتی در بزرگراه خط خود را تغییر می دهید باید",
      answers: [
        "قبل از تغییر خط چراغ راهنما بزنید",
        "بدون زمین چراغ راهنما، تغییر خط بدهید",
        "همیشه قبل از اینکه شروع به تغییر خط کنید از سرعت خود کم کنید"
      ],
      correctAnswer: 1,
      id: "ZMOmTHR"
    },
    {
     question: "شما از خروجی یک بزرگراه که سراشیب است خارج می شوید باید",
      answers: [
        "به سرعتی که برای آزادراه تعیین شده است، تغییر وضعیت دهید",
        "قبل از پیچ سرعت خود را تا سرعت مطمئن کاهش دهید",
        "پس از ورود به پیچ ترمز کردن را آغاز کنید"
      ],
      correctAnswer: 3,
      id: "Uvc5UNC"
    },
    {
     question: "غالب میخهای زرد رنگ (چشم گربه) در چه محلهایی نصب می‌شود",
      answers: [
        "در مسیرهای ورودی و خروجی آزادراه ها",
        "در باند کاهش سرعت",
        "در مسیر رفت و برگشت جاده‌ها و در سمت چپ مسیر"
      ],
      correctAnswer: 3,
      id: "oDpsaAT"
    },
    {
     question: "در مواقع طوفانی و وزش باد شدید کدامیک از موارد زیر باید بیش از حد مراقب باشید",
      answers: [
        "به هنگام عبور از کنار دوچرخه و موتورسواران",
        "به هنگام استفاده از ترمزها",
        "به هنگام عبور از پیچ‌ها و سربالایی‌ها"
      ],
      correctAnswer: 1,
      id: "XtBnw_0"
    },
    {
     question: "حرکت با دنده خلاص در سرپایینی چه خطراتی را در پی خواهد داشت",
      answers: [
        "موجب سرعت گرفتن خودرو شده و دنده سخت جلو می‌رود",
        "ترمز گرفتن و هدایت خودرو مشکل می‌شود",
        "موارد 1 و 2"
      ],
      correctAnswer: 3,
      id: "3QA-aAk"
    },
    {
     question: "سبقت گرفتن در سر پیچ‌های تند و سربالایی‌ها به چه علت ممنوع می‌باشد",
      answers: [
        "نامحدود بودن میدان دید راننده",
        "کم بودن میدان دید راننده",
        "اختلال در ترافیک"
      ],
      correctAnswer: 2,
      id: "Oq9DyI0"
    },
    {
     question: "با مشاهده چراغ چشمک زن زرد در تقاطع چه عملی را انجام می‌دهیم",
      answers: [
        "ایست کامل کرده وبارعایت حق تقدم حرکت میکنیم",
        "از سرعت خود کاسته و با احتیاط عبور میکنیم",
        "به حرکت خود ادامه می‌دهیم"
      ],
      correctAnswer: 2,
      id: "AEFDZrX"
    },
    {
     question: "معاینه و آزمایش فنی وسایل نقلیه شامل چه مواردی می‌شود؟",
      answers: [
        "سلامت زیست محیطی",
        "سلامت فنی، تجهیزات و ایمنی",
        "همه موارد"
      ],
      correctAnswer: 3,
      id: "qsN3rm9"
    },
    {
     question: "در هنگام رانندگی و برخورد با موارد اضطراری انجام کدام موارد ضروری می‌باشد",
      answers: [
        "نگهداشتن هر دو دست بر روی فرمان",
        "نحوه گردش صحیح و ایمن",
        "موارد 1 و 2 صحیح است"
      ],
      correctAnswer: 3,
      id: "9BYNUd7"
    },
    {
     question: "برای تغییر مسیر و تعویض خط حرکت چگونه باید عمل نمود",
      answers: [
        "فقط با استفاده از چراغ راهنما تغییر مسیر می‌دهیم",
        "با دادن علامت وسایل نقلیه دیگر را از قصد خود آگاه نموده و با رعایت حق تقدم عبور و احتیاط کامل تغییر مسیر می‌دهیم",
        "با استفاده از آینه تغییر مسیر می‌دهیم"
      ],
      correctAnswer: 2,
      id: "PP6hzDI"
    },
    {
     question: "موقعی که اتومبیل خود را پارک می‌کنید نباید ",
      answers: [
        "در دنده بگذارید",
        "ترمز دستی را بکشید",
        "مانع سایر استفاده کنندگان از راه شوید"
      ],
      correctAnswer: 3,
      id: "2fIcRCB"
    },
    {
     question: "چرا در جاده پرترافیک باید از خودرو جلویی فاصله بیشتری گرفت",
      answers: [
        "برای جلوگیری از خستگی چشمها",
        "چون اینکار باعث روان شدن جریان ترافیک میگردد",
        "برای کاهش خطر حادثه و تصادف زنجیره‍ای"
      ],
      correctAnswer: 3,
      id: "mmKrep0"
    },
    {
     question: "کدامیک از کارهای زیر را نباید در پمپ بنزین انجام داد",
      answers: ["استعمال دخانیات", "خوردن", "آشامیدن"],
      correctAnswer: 1,
      id: "hNNFIfA"
    }, {
      question: "کدام یک از این اظهارات در مورد سایر رانندگان صدق می کند",
      answers: [
        "رانندگان همیشه علامات و چراغ های رانندگی را مراعات می کنند",
        "رانندگان با استفاده از راهنما برای گردش به جهتی که مشخص می شود گردش می کند",
        "هیچ وقت فرض نکنید که رانندگان دیگر حق تقدم را به شما خواهند داد"
      ],
      correctAnswer: 3,
      id: 1
    },
    {
      question: "زمانی که پارک می کنید چراغ های جلو را به طرف جدول بچرخانید ",
      answers: [
        "بطرف سرازیری ",
        "بطرف سر بالایی",
        "در یک جاده مسطح "
      ],
      correctAnswer: 1,
      id: 2
    },
    {
      question: "هنگامی که در خط راه آهن که دارای خطوط متعدد است توقف کرده اید ",
      answers: [
        "زمانی که بتوانید هر دو جهت را واضح ببینید ",
        "به محض اینکه قطار از جاده شما رد شد عبور کنید",
        "فقط زمانی که اتوموبیل های دیگر شروع به عبور می کنند، عبور کنید"
      ],
      correctAnswer: 1,
      id: 3
    },
    {
      question: "شما می خواهید از یک جای پارک بصورت زاویه (کج) می باشد دنده عقب خارج شوید. همیشه باید به آهستگی دنده عقب آمده و نگاه کنید",
      answers: [
        "در حین دنده عقب رفتن باید همیشه در آنیه پشت نگاه کنید",
        "در حین دنده عقب رفتن باید همیشه در آینه های بغل نگاه کنید",
        "در حین عقب رفتن نگاه کردن از روی شانه"
      ],
      correctAnswer: 3,
      id: 4
    },
    {
      question: "زمانی که خط عابر پیاده یا خط محدوده وجود ندارد کجا باید اتومبیل خود را متوقف نمایید",
      answers: [
        "درست پس از نبش (گوشه)",
        "سر نبش",
        "بیست پا قبل از نبش"
      ],
      correctAnswer: 2,
      id: 5
    },
    {
      question: "بهترین توصیه برای رانندگی هنگامی که مه شدید یا گرد و خاک رخ می دهد چه می باشد",
      answers: [
        "اقدام نکردن به رانندگی تا وضع بهبود پیدا کند",
        "خیلی آهسته حرکت نکردن چون ممکن است رانندگان دیگر به شما بزنند",
        "چراغ های نور بالا و پایین را بتناوب برای بهبود دیدتان استفاده کنید"
      ],
      correctAnswer: 1,
      id: 6
    },
    {
      question: "اگر اتومبیلی که از جهت مخالف می آید و در جلوی شما شروع به گردش به چپ کرده باشد ",
      answers: [
        "برای اخطار به راننده بوق بزنید و سرعت خود را حفظ کنید",
        "سرعت خود را حفظ کنید و حق تقدم خود را بگیرید",
        "برای جلوگیری از تصادف سرعت را کاهش داده یا توقف کنید"
      ],
      correctAnswer: 3,
      id: 7
    },
    {
      question: "شما باید برای اتومبیل های اضطراری بطریق زیر حق تقدم قائل شوید",
      answers: [
        "رانندگی به کناره راست جاده تا حد ممکن و توقف کردن",
        "وارد شدن در خط دست راست و به آهستگی رانندگی کردن تا اتومبیل اضطراری رد شود",
        "توقف بلافاصله، حتی اگر در چهار راه می باشید"
      ],
      correctAnswer: 1,
      id: 8
    },
    {
      question: "یک خودرو سنگین در خط وسط یک جاده سه بانده در حال حرکت است. می خواهید از خودرو بزرگ سبقت بگیرید. بهترین کار این است",
      answers: [
        "سریع از طرف چپ سبقت بگیرید و از آن جلو بزنید",
        "بسیار آهسته از طرف چپ سبقت بگیرید و از آن جلو بزنید",
        "بسیار سریع تر از طرف راست سبقت بگیرید و از آن جلو بزنید"
      ],
      correctAnswer: 1,
      id: 9
    },
    {
      question: "اگر شما را با غلظت الکل خون بالا یا در حد قانونی بگیرند",
      answers: [
        "ممکن است دادگاه شما را جریمه کند",
        "آزمایش استنشاق شما به تنهایی نمی تواند شما را محکوم کند",
        "گواهینامه رانندگی شما به طور اتوماتیک باطل خواهد شد، بدون در نظر گرفتن وضع گواهینامه"
      ],
      correctAnswer: 1,
      id: 10
    },
    {
      question: "شما در یک خیابان تقسیم شده که در جهت شما چندین خط دارد در حال رانندگی هستید. اگر باید دور بزنید، از کجا باید شروع کنید",
      answers: [
        "از خط وسط خیابان",
        "از کناره خیابان",
        "از خط سمت چپ"
      ],
      correctAnswer: 3,
      id: 11
    },
    {
      question: "یک اتوبوس مدرسه در جاده در طرف شما مقداری جلوتر متوقف شده است و چراغ های قرمز آن چشمتک می زند. چی کار باید بکنید",
      answers: [
        "اول توقف کنید، سپس زمانی که فکر می کنید امن ادامه دهید",
        "تا زمانی که چراغ های قرمز چشمک می زند توقف کنید",
        "توقف کنید تا همه کودکان از خط شما عبور کرده اند"
      ],
      correctAnswer: 2,
      id: 12
    },
    {
      question: "یک علامت پیکان سبز به طرف چپ در چراغ راهنمایی بدین معنی است که ",
      answers: [
        "یک گردش محفوظ به آن جهت انجام دهید",
        "گردش به چپ بکنید تا مستقیم ادامه دهید",
        "فقط بعد از توقف به آن جهت گردش نمایید"
      ],
      correctAnswer: 1,
      id: 13
    },
    {
      question: "منطقه ایمنی، ناحیه ای است که مخصوص مسافران برای سوار و پیاده شدن از اتوبوس یا واگن در نظر گرفته شده است. نمی توانید از ناحیه ایمنی عبور کنید",
      answers: [
        "فقط هنگامی که اتوبوس یا واگن در آنجا باشد",
        "فقط هنگامی که اتوبوس یا واگن درحال سوار کردن مسافر می باشد",
        "در هر زمان یا به هر دلیل"
      ],
      correctAnswer: 3,
      id: 14
    },
    {
      question: "برای کمک به جلوگیری از سر خوردن در سطوح لغزنده باید",
      answers: [
        "هنگام پایین رفتن از یک سر پایینی، دنده را کمتر کنید",
        "هنگام ورود به پیچ سرعت بگیرید و هنگام خروج از آنان آهسته برانید",
        "قبل از ورود به پیچ ها و تقاطع ها آهسته کنید"
      ],
      correctAnswer: 3,
      id: 15
    },
    {
      question: "کدام یک از این اظهارات در مورد موتور سرعت رانندگی صدق می کند",
      answers: [
        "کالیفورنیا دارای قانون حداقل سرعت نمی باشد",
        "هر چه سرعت بالاتر باشد، کنترل کمتری روی خودروی خود خواهید داشت",
        "فراتر رفتن از سرعت تعیین شده برای عبور از سایر خودروها قانونی می باشد"
      ],
      correctAnswer: 2,
      id: 16
    },
    {
      question: "علامت نارنجی رنگ جاده به شما اخطار می دهد که ",
      answers: [
        "مدرسه در پیش است",
        "علامت راهنمایی و رانندگی در پیش است",
        "کارگران در جاده یا ماشین آلات جاده در پیش است"
      ],
      correctAnswer: 3,
      id: 17
    },
    {
      question: "کدام یک از پاسخ های زیر در مورد استفاده از چراغ راهنمایی اتومبیل صدق می کند",
      answers: [
        "مدرسه در پیش است",
        "اگر برای خط عوش کردن علامت بزنید، رانندگان دیگر به شما اجازه بدهند که وارد بشوید",
        "عادت خوبی است که همیشه برای خط عوض کردن علامت بزنید"
      ],
      correctAnswer: 3,
      id: 18
    },
    {
      question: "زیگزاگ رفتن در میان خطوط بزرگراه هنگام ترافیک سنگین",
      answers: [
        "جراین ترافیک را با بوجود آوردن جا برای اتومبیل های دیگر برای ادغام شدن بهبود می بخشد",
        "تراکم ترافیک را بیشتر می کند زیرا از سرعت اتومبیل های دیگر می کاهد",
        "بازده سوخت بنزین را افزایش می دهد"
      ],
      correctAnswer: 2,
      id: 19
    },
    {
      question: "اگر در بغل جدول دست راست بطرف سربالا پارک کنید",
      answers: [
        "چرخ های خود را مستقیم بطرف جلو نگهدارید",
        "چرخ های جلوئی خود را بطرف راست بچرخانید",
        "چرخ های جلوئی خود را بطر چپ بچرخانید"
      ],
      correctAnswer: 3,
      id: 20
    },
    {
      question: "ادغام (وارد شدن) به جاده امن تر است اگر شما",
      answers: [
        "به آهستگی بطرف نزدیک ترین خط حرکت کنید تا رانندگان دیگر برای شما جا باز کنند",
        "در کنار جاده رانندگی کنید تا جا باز شود",
        "صبر کنید تا یک فاصله ای که به اندازه کافی بزرگ می باشد در ترافیک باز شود"
      ],
      correctAnswer: 3,
      id: 21
    },
    {
      question: "شما وارد یک بزرگراه می شوید. باید ترافیک را چک کنید از طریق ",
      answers: [
        "از روی شانه خود نگاه کنید",
        "فقط از آینه های داخلی و خارجی استفاده نمایید",
        "کلیه آینه ها را چک کنید و سرتان را بچرخانید"
      ],
      correctAnswer: 3,
      id: 22
    },
    {
      question: "خطوط ممتد زرد رنگ چه چیزی را جدا می کند",
      answers: [
        "خطوط ترافیک را در یک خیابان یک طرفه",
        "خطوط دوچرخه را از ترافیک معمولی",
        "اتومبیل هایی که در جهت مخالف در حال حرکت هستند"
      ],
      correctAnswer: 3,
      id: 23
    },
    {
      question: "کدام از یک از کودکان زیر به سیستم نگهداری حفاظتی برای سرنشینان کودک لازم دارند",
      answers: [
        "شش ساله که 60 پوند وزن دارد",
        "پنج ساله که 55 پوند وزن دارد",
        "پنج ساله که 65 پوند وزن دارد"
      ],
      correctAnswer: 2,
      id: 24
    },
    {
      question: "رانندگان کامیون های بزرگ اغلب با فاصله زیادی از جلو خودروشان حرکت می کنند. این فضای اضافه برای این لازم است که ",
      answers: [
        "سایر رانندگان هنگام ادغام به بزرگراه استفاده کنند",
        "راننده کامیون برای توقف خودرو از آن استفاده کند",
        "سایر رانندگان هنگامی که می خواهند سرعت کم کنند از آن استفاده می کنند"
      ],
      correctAnswer: 2,
      id: 25
    },
    {
      question: "سه زمان مهم برای بررسی ترافیک پشت سر کدام موارد زیر می باشد",
      answers: [
        "عقب رفتن، چرخش تیز انجام دادن یا گذشتن از تقاطع",
        "عقب رفتن، عوض کردن خط یا کم کردن سریع سرعت",
        "عوض کردن خط، عبور از تقاطع یا کم کردن سریع سرعت"
      ],
      correctAnswer: 2,
      id: 26
    },
    {
      question: "یک علامت پیکان قرمز به طرف راست در چراغ راهنمایی بدین معنی است که ",
      answers: [
        "بعد از کاهش سرعت و بررسی ترافیک به آن جهت گردش کنید",
        "تا زمانی که چراغ سبز شود به آن سمت گردش نکنید",
        "بعد از توقف کامل به آن سمت گردش کنید"
      ],
      correctAnswer: 2,
      id: 27
    },
    {
      question: "باید مراقب دوچرخه سوارانی که از همان مسیر مورد استفاده خودروهای موتوری استفاده می کنند باشید چون آن ها",
      answers: [
        "باید مقابل ترافیک در حال آمدن حرکت کنند",
        "بطور غیرقانونی از خطوط خودروهای موتوری استفاده می کنند",
        "می توانند در جاده با شما سهیم باشند"
      ],
      correctAnswer: 3,
      id: 28
    },
    {
      question: "در حالت زیر چراغ های ترمز را روشن کنید یا چراغ های چشم زن اضطراری را روشن کنید",
      answers: [
        "می خواهید به سایر رانندگان در مورد تصادف جلو هشدار دهید",
        "بعد از پارک موقت در خط ترافیک برای تحویل یک بسته ",
        "بعد از عقب رفتن از فضای پارکینگ"
      ],
      correctAnswer: 1,
      id: 29
    },
    {
      question: "اتومبیلی ناگهان در جلوی شما راه را می برد و ایجاد خطر می کند. کدام یک از این اقدامات باید اول انجام شود",
      answers: [
        "بوق زدن و محکم ترمز گرفتن",
        "برداشتن پا از روی گاز",
        "منحرف شدن به خط کنار "
      ],
      correctAnswer: 2,
      id: 30
    },
    {
      question: "در حال رانندگی هستید و خودروهایی از سمت چپ به طرف شما می آیند و چند ردیف خودرو هم سمت راست شما پارک شده است. شما باید دراین مسیر حرکت کنید",
      answers: [
        "نزدیک تر به خودروهایی که به شما نزدیکتر می شوند تا خودروهای پارک شده",
        "نزدیک تر به خودروهایی پارک شده تا خودرو اهی در حال آمدن",
        "در مسیری بین خودروهای در حال آمدن و خودروهای پارک شده"
      ],
      correctAnswer: 3,
      id: 31
    },
    {
      question: "چه هنگام باید حق تقدم خود را واگذار کنید",
      answers: [
        "اغلب مواقع، حتی در تقاطع های کنترل شده",
        "هنگامی که به جلوگیری از تصادفات کمک می کند",
        "هرگز، این باعث گیج شدن سایر رانندگان می شود"
      ],
      correctAnswer: 2,
      id: 32
    },
    {
      question: "از خط های دوگانه ممتد زرد رنگ وسط جاده برای دلیل زیر استفاده نکنید",
      answers: [
        "سبقت گرفتن از اتومبیل های دیگر ",
        "برای گردش به چپ ",
        "وارد نشدن به ساختمان/گاراژ"
      ],
      correctAnswer: 1,
      id: 33
    },
    {
      question: "در کدام حالت باید در وقت پارک کردن چرخ های جلوی خود را بطرف جدول بچرخانید",
      answers: [
        "وقتی که در سرازیری پارک می کنید",
        "وقتی که در سربالایی پارک می کنید",
        "وقتی که در یک جاده مسطح پارک می کنید"
      ],
      correctAnswer: 1,
      id: 34
    },
    {
      question: "همیشه پیش از اینکه گردش کنید با احتیاط دنبال موتورسیکلت ها بگردید زیرا ",
      answers: [
        "آنها باید یک خط کامل ترافیک داشته باشند",
        "آنها همیشه در سرچهارراه ها حق تقدم دارند",
        "اندازه کوچک شان دیدن آنها را دشوار می کند"
      ],
      correctAnswer: 3,
      id: 35
    },
    {
      question: "در یک چهار راهی که با علائم راهنمایی کنترول نمی شود و شما نمی توانید ترافیک متقاطع را ببینید تا آنجایی که دارید وارد چهار راه می شوید حد سرعت کدام می باشد",
      answers: [
        "پانزده مایل در ساعت",
        "بیست و پنج مایل در ساعت",
        "بیست مایل در ساعت"
      ],
      correctAnswer: 1,
      id: 36
    },
    {
      question: "در روزهای بارانی، برفی یا مه گرفته برف پاکن های خود را بکار بیندازید و چراغ های جلویی خود را استفاده نمایید",
      answers: [
        "با نور بالا",
        "طوری که رانندگان دیگر بتوانند شما را ببینند",
        "فقط زمانی که در بزرگراه رانندگی می کنید"
      ],
      correctAnswer: 2,
      id: 37
    },
    {
      question: "اگر اتومبیل شما بعلت آب در جاده شروع به از دست دادن کشش می نماید (هیدروپلین) شما باید",
      answers: [
        "با سرعت پیوسته برای بدست آوردن کشش بهتر رانندگی نمایید",
        "برای جلوگیری از لغزش اتومبیل خود بطور محکم ترمز نمایید",
        "به تدریج سرعت خود را کم کرده و ترمز نمایید"
      ],
      correctAnswer: 3,
      id: 38
    },
    {
      question: "کدام یک از موارد زیر طریقه صحیح برای خط عوض کردن می باشد",
      answers: [
        "زدن راهنما، چک کردن آینه های خود، و سپس عوض کردن خط",
        "زدن راهنما، و نگاه کردن از روی شانه خود قبل از عوض کردن خط",
        "چک کردن آینه های خود، نگاه کردن از روی شانه خود و سپس عوض کردن خط"
      ],
      correctAnswer: 2,
      id: 39
    },
    {
      question: "وارد شدن به داخل چهار راه بر خلاف قانون می باشد زمانی که",
      answers: [
        "نمی توانید پیش از اینکه چراغ قرمز شود کاملا عبور کرده به آن طرف برسید",
        "چراغ زرک چشمک میزند و شما ابتدا توقف نکردید",
        "چراغ زرد است و شما نمی توانید بصورت امن توقف نمایید"
      ],
      correctAnswer: 1,
      id: 40
    },
    {
      question: "چه زمانی بطور قانونی می توانید دروازه عبور راه آهن را دور بزنید یا از زیر آن رد شوید",
      answers: [
        "تحت هیچ شرایطی",
        "زمانی که بطور واضح بتوانید هر دو جهت را ببینید",
        "زمانی که چراغ های اخطار چشمک نمی زنند"
      ],
      correctAnswer: 1,
      id: 41
    },
    {
      question: "کدام یک از موارد زیر در مورد موتورسیکلت ها صدق می کند",
      answers: [
        "موتورسیکلت ها کوچک می باشند و به آسانی می توانند توسط رانندگان دیده شوند",
        "موتورسیکلت ها ممکن است در خط های ترافیک حرکت نکنند",
        "باید با فاصله بیشتری دنبال موتورسیکلت ها حرکت کرد"
      ],
      correctAnswer: 3,
      id: 42
    },
    {
      question: "کدام یک از این اظهارات در مورد نقطه کور صدق می کند",
      answers: [
        "برای عوض کردن خط بطرف راست یا چپ شما فقط باید برگردید و از روی شانه راست خود نگاه کنید",
        "برای عوض کردن خط به خط دست راست از روی شانه راست خود نگاه کنید و باری خط دست چپ از روی شانه چپ خود نگاه کنید",
        "اتومبیل هایی که دارای دو آینه بیرونی می باشند دارای نقطه کور نمی باشند"
      ],
      correctAnswer: 2,
      id: 43
    },
    {
      question: "حداکثر سرعت برای وضعیت رانندگی ایده آل",
      answers: [
        "سرعت اتومبیل های دیگری که در جهت شما رانندگی می کنند می باشد",
        "سرعت ذکر شده برای جاده یا شاهراهی که شما استفاده می کنید می باشد",
        "هر سرعتی که شما احساس می کنید برای شما و اتومبیل تان امن می باشد"
      ],
      correctAnswer: 2,
      id: 44
    },
    {
      question: "هنگامی که در منتهی الیه خط دست راست بزرگراه حرکت می کنید شما",
      answers: [
        "باید انتظار داشته باشید که از راه دخولی اتومبیل ها ادغام شوند",
        "باید آهسته تر از اتومبیل های دیگر حرکت نمایید",
        "باید حق تقدم را به اتومبیل هایی که ادغام می شوند بدهید"
      ],
      correctAnswer: 1,
      id: 45
    },
    {
      question: "شما نباید از اتومبیل دیگر سبقت بگیرید",
      answers: [
        "در جائی که احتمال دارد شخصی وارد جاده شود یا از آن عبور نماید",
        "در یک خیابان یکطرفه با خط های متعدد",
        "زمانی که یک خط زرد رنگ شکسته در طرف چپ خط شما وجود دارد"
      ],
      correctAnswer: 1,
      id: 46
    },
    {
      question: "رانندگی در طرف راست پشت ماشین دیگر ",
      answers: [
        "خطرناک می باشد زیرا احتمالا شما در یکی از نقطه های کور راننده هستید",
        "یک تکنیک رانندگی دفاعی خوبی برای احتراز از نقطه کور راننده می باشد",
        "روش خوبی جهت برقراری فاصله حفاظتی در طرف چپ تان می باشد"
      ],
      correctAnswer: 1,
      id: 47
    },
    {
      question: "کامیون های بزرگ احتمال زیادی دارند که سرعت خود را از دست بدهند و خطر ایجاد کنند",
      answers: [
        "در پیچ های طولانی و تدریجی",
        "هنگام بالا رفتن از تپه های طولانی و سراشیب",
        "هنگام پایین رفتن از تپه های طولانی تدریجی"
      ],
      correctAnswer: 3,
      id: 48
    },
    {
      question: "بزرگراه در نتیجه باران سنگین خیس می باشد. شما باید",
      answers: [
        "از قانون فاصله دو ثانیه عقب تر استفاده کنید",
        "بفاصله درازای یک ماشین پشت سر ماشین جلویی رانندگی کنید",
        "فاصل مابین اتومیل خود و ماشین جلویی را بیشتر کنید "
      ],
      correctAnswer: 3,
      id: 49
    },
    {
      question: "شما باید با دقت مواظب دوچرخه های باشید زیرا آنها",
      answers: [
        "باید از جهت مخالف ترافیکی که در حال حرکت است رانندگی نمایند",
        "می توانند در نقطه کور شما پنهان شده باشند",
        "معمولا حق تقدم دارند"
      ],
      correctAnswer: 2,
      id: 50
    },
    {
      question: "در یک آزاد راه، باید نسبت به خیابان در شهر به دورتر نگاه کنید به خاطر اینکه",
      answers: [
        "خطرات احتمالی را زودتر ببینید",
        "چون 1/4 مایل برای توقف کامل خودرو نیاز است",
        "چون به شما کمک می کند تا ترافیک هماهنگ شوید"
      ],
      correctAnswer: 1,
      id: 111
    },
    {
      question: "شما از خروجی اتوبان که دارای پیچ سرازیری می باشد خارج می شوید، شما باید",
      answers: [
        "قبل از پیچ سرعت د را به سرعت مطمئن کاهش دهید",
        "سرعت خود را به سرعت تعیین شده برای آزاد راه تغییر دهید",
        "قبل از ترمز کردن، صبر کنید تا وارد پیچ شوید"
      ],
      correctAnswer: 1,
      id: 222
    },
    {
      question: "جدولی که رنگ آن آبی باشد به این معنی است که پارکینگ",
      answers: [
        "برای بیش از 15 دقیقه مجاز نمی باشد",
        "برای سوار کردن یا پیاده کردن مسافران",
        "برای افراد معلول با کارت یا پلاکارد ویژه "
      ],
      correctAnswer: 3,
      id: 333
    },
    {
      question: "کدام یک از این اظهارات در مورد رانندگی و خوردن مشروبات الکلی صدق می کند",
      answers: [
        "اگر بتواند بعد از خوردن مشروب روی یک خط صاف راه بروید، رانندگی امن و بی خطر است",
        "اگر میزان الکل خون شما زیر حد قانونی باشد، رانندگی شما اشکال ندارد",
        "الکل روی تشخیص و کنترل شخصی که برای رانندگی امن لازم است تاثیر می گذارد"
      ],
      correctAnswer: 3,
      id: 444
    },
    {
      question: "یک عابر پیاده، بعد از شروع چشم زدن علامت «عبور نکنید» شروع به رفتن به سمت دیگر خیابان می کند. هنگامی که چراغ شما سبز می شود عابر در وسط خیابان است. شما باید",
      answers: [
        "در صورتی که حق تقدم دارید باید حرکت کنید",
        "در صورتی که عابر در خط شما نباشد حرکت کنید",
        "قبل از حرکت، صبر کنید تا عابر از خیابان عبور کند"
      ],
      correctAnswer: 3,
      id: 555
    },
    {
      question: "شما در بزرگراهی که سرعت آن 65 مایل در ساعت است رانندگی می کنید. ترافیک سنگین است و با سرعت 35 مایل در سارعت در حال حرکت است. بهترین سرعت برای خودرو شما احتمالا به مقدار زیر می باشد",
      answers: [
        "25 مایل در ساعت",
        "35 مایل در ساعت",
        "30 مایل در ساعت "
      ],
      correctAnswer: 2,
      id: 666
    },
    {
      question: "یک عابر پیاده که نابینا است یا دارای نقص بینایی است از اصوات ترافیکی قبل از تصمیم در مورد عبور از خیابان تصمیم می گیرد. اگر شما عابری را ببینید که عصای سفید یا سگ راهنما دارد و در نبش خیابان قصد عبور از خیابان را دارد باید",
      answers: [
        "در خط عابر پیاده توقف کنید و بوق بزنید",
        "وارد خط کشی شوید تا آن فرد صدای موتور شما را بشنود",
        "تا اول خط کشی بروید تا آن فرد صدای موتور شما را بشنود"
      ],
      correctAnswer: 3,
      id: 777
    },
    {
      question: "کامیون های بزرگ احتمال زیاد دارند که سرعت خود را از دست بدهند و خطر ایجاد کنند",
      answers: [
        "در پیچ های طولانی و تدریجی",
        "هنگام بالا رفتن از تپه های طولانی و سراشیب",
        "هنگام پایین رفتن از تپه های طولانی تدریجی"
      ],
      correctAnswer: 3,
      id: 888
    },
    {
      question: "شما با سرعت 55 مایل در ساعت در منتهی الیه خط چپ در بزرگراه که حد سرعت آن 65 مایل در ساعت نصب شده است در حال رانندگی هستید. ممکن است برای اینکه بسیار آهسته رانندگی جریمه شوید ",
      answers: [
        "اگر شرایط جاده یا هوا رانندگی به این آهستگی را حکم می کند",
        "اگر جریان معمولی و مقتضی ترافیک را مسدود می کنید",
        "تحت هیچ شرایطی زیرا همیشه امن تر است که آهسته تر از ترافیک دیگر حرکت کنید"
      ],
      correctAnswer: 2,
      id: 999
    },
    {
      question: "شخص می تواند بصورت قانونی در پشت یک وانت باری سوار شود زمانی که",
      answers: [
        "بغل های وانت باری حداقل 24 اینچ ارتفاع داشته باشد",
        "پشت وانت باری با اطاقک پوشانده شده باشد",
        "در صندلی های امن و با استفاده از کمربندهای ایمنی تایید شده"
      ],
      correctAnswer: 3,
      id: 101010
    }
  ]

export default quizes