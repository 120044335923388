import airportImg from "../images/Airport.jpg";
import approachStop from "../images/Approachingstop.png";
import bicycleImg from "../images/Bicycles.png";
import bridgeIce from "../images/Bridgeices.png";
import busStation from "../images/Busstation.jpg";
import crossBuckImg from "../images/crossbuck-sign.jpg";
import deadEnd from "../images/dead-end-sign.jpg";
import dearCrossing from "../images/dear-crossing-sign.jpg";
import dipSign from "../images/dip-sign.jpg";
import disabledParking from "../images/Disabledparking.png";
import dividedHighway from "../images/divided-highway-sign.jpg";
import doNotEnter from "../images/do-not-enter.jpg";
import doNotPass from "../images/do-not-pass-sign.jpg";
import farmVehicle from "../images/farm-vehicle.png";
import flaggerSign from "../images/Flagger.png";
import interstateSing from "../images/interstate-sign.jpg";
import keepRightSign from "../images/keep-right-sign.jpg";
import lowClearance from "../images/Lowclearance.png";
import menAtWork from "../images/men-at-work.jpg";
import mergeSign from "../images/merge-sign.jpg";
import narrowBridge from "../images/narrow-bridge.jpg";
import noLeftTurn from "../images/no-left-turn.jpg";
import noPassZone from "../images/no-passing-zone.jpg";
import noUTurn from "../images/no-u-turn.jpg";
import noParking from "../images/NoParking.png";
import noTrucks from "../images/Notrucks.png";
import oneWay from "../images/one-way.jpg";
import pedesterianCrossing from "../images/pedesterian-crossing.jpg";
import photoEnforced from "../images/Photoenforced.png";
import railRoadCrossing from "../images/rail-road-crossing.jpg";
import roadNarrow from "../images/road-narrow.jpg";
import roundAbout from "../images/Roundabout.png";
import schoolZone from "../images/school-zone.jpg";
import slipperyRoad from "../images/slippery-road.jpg";
import slowMovingVehicle from "../images/slow-moving-vehicle.jpg";
import speedLimit from "../images/speed-limit.jpg";
import trafficSignal from "../images/Trafficsignal.png";
import twoWayAhead from "../images/two-way-ahead.jpg";
import windingRoad from "../images/winding-road.jpg";
import wrongWay from "../images/Wrongway.png";
import yieldSign from "../images/yield-sign.jpg";
import approachIntersection from '../images/approachIntersection.jpg'
import bikeLaneNoPark from '../images/bikeLaneNoStop.jpg'
import sharpTurn from '../images/sharpTurn.jpg'
import approachingDip from '../images/dipComing.jpg'
import stopOnlyEmergency from '../images/emergencyStopOnly.jpg'
import endRoadWork from '../images/endRoadWork.jpg'
import exitOnly from '../images/exitOnly.jpg'
import fireStation from '../images/fireStation.jpg'
import foodSign from '../images/foodSign.jpg'
import gasStation from '../images/gasStation.jpg'
import hospitalSign from '../images/hospialSign.jpg'
import hovSign from '../images/hovCarpool.jpg'
import laneAdded from '../images/laneAdded.jpg'
import laneControl from '../images/laneControl.jpg'
import leftOrStraight from '../images/leftStraight.jpg'
import leftOrRight from '../images/leftRightTurn.jpg'
import liveStock from '../images/liveStock.jpg'
import lodging from '../images/lodgeSign.jpg'
import noLeftUturn from '../images/noLeftOrUTurn.jpg'
import onlyRight from '../images/rightTurnOnly.jpg'
import roadWorkAhead from '../images/roadWorkAhead.jpg'
import yieldPedestrian from '../images/yieldPedestrians.jpg'
import bikesMayUseFull from '../images/bikesMayUseFullLane.jpg'
import approachingBusStation from '../images/approachingBusStation.jpg'
import highwayDivided from '../images/dividedHighway.jpg'
import evStation from '../images/evStation.jpg'
import fullTurn from '../images/180Turn.jpg'



const signs = [
  {
    id: "0f8347a2-cad2-4b05-9861-e386b35e7a8b",
    question: "این علامت به چه معنی است ",
    correctAnswer: 1,
    answers: [
      "در جلو فرودگاه قرار دارد",
      "به معنی باند اضطراری هواپیما است",
      "ممکن است در روبرو هواپیمای تجاری به زمین بنشیند",
    ],
    timestamp: 1660751901459,
    signImg: airportImg,
  },
  {
    id: "536c0f87-4304-4c57-be3b-935668bfff1b",
    question: "این علامت به چه معنی است ",
    correctAnswer: 2,
    answers: [
      "در روبرو تصادف شده است",
      "در حال نزدیک شدن به تابلوی توقف هستید",
      "در آن سوی جاده هوا بارانی است",
    ],
    timestamp: 1660755624017,
    signImg: approachStop,
  },
  {
    id: "620900f4-1e87-454f-a84a-ed5b1e860c51",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "شما باید از دوچرخه استفاده کنید تا سلامت بمانید",
      "تابلوی حضور دوچرخه سواران می باشد",
      "دوچرخه سواران مواظب باشند تا تصادف نکنند",
    ],
    timestamp: 1660755646249,
    signImg: bicycleImg,
  },
  {
    id: "e6262824-f238-45e6-9e56-d85e13127ce9",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "پل ها دچار ازدحام ترافیکی می باشند",
      "باید تا می توانید از گوشه های پل عبور کنید",
      "پل دچار یخ بندان شده است",
    ],
    timestamp: 1660755668932,
    signImg: bridgeIce,
  },
  {
    id: "cdf4ae8c-a29a-40bc-8ef5-e31262230248",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "علامت ایستگاه اتوبوس می باشد",
      "قطار در حال حرکت است",
      "این مسیر تنها برای استفاده اتوبوس می باشد",
    ],
    timestamp: 1660755711901,
    signImg: busStation,
  },
  {
    id: "76279c6a-cc53-4f31-8dea-24af7e98dd69",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "علامت عبور قطار می باشد",
      "در روبرو تظاهرات جریان دارد",
      "از منطقه تجاری محافظت می کند",
    ],
    timestamp: 1660755728326,
    signImg: crossBuckImg,
  },
  {
    id: "13acc305-4920-4349-af06-9576d458f66c",
    question: "این علامت به چه معنی است ",
    correctAnswer: 2,
    answers: [
      "در آن طرف جاده یک جسد قرار دارد",
      "این جاده بن بست است",
      "امکان مراسم خاکسپاری می باشد",
    ],
    timestamp: 1660755746318,
    signImg: deadEnd,
  },
  {
    id: "e645cda4-1a5c-4ff6-a6c2-2997a869fd10",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: ["منطقه شکار آهو ", "امکان عبور آهو ", "آهوها را شکار نکنید"],
    timestamp: 1660755762862,
    signImg: dearCrossing,
  },
  {
    id: "e67fef25-1d96-4c95-aeff-df7db616288e",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "علامت شیب در جاده ",
      "امکان حضور پلیس بزرگراه ",
      "چراغ های راهنما را روشن کنید",
    ],
    timestamp: 1660755782623,
    signImg: dipSign,
  },
  {
    id: "e4fc4162-7910-402a-b308-25eba76de8cc",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "این پارکینگ مخصوص معیوبین است",
      "در دو طرف جاده بیمارستان قرار دارد",
      "حق اولویت عبور برای معیوبین",
    ],
    timestamp: 1660755804025,
    signImg: disabledParking,
  },
  {
    id: "5beb104f-ed4c-4bb3-8aec-536c2b5e224c",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "به معنی وجود کوه در ادامه جاده ",
      "بزرگراه در ادامه مسیر به دو جاده تقسیم شده است",
      "مواظب راننده های بی احتیاط باشید",
    ],
    timestamp: 1660755822369,
    signImg: dividedHighway,
  },
  {
    id: "1dfab33b-93b5-4f7e-89f2-738d3c92de52",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "به این جاده وارد نشوید",
      "از گوشه های جاده برانید",
      "برای ایست کامل آماده باشید",
    ],
    timestamp: 1660755842209,
    signImg: doNotEnter,
  },
  {
    id: "6721151c-9e9e-472f-9f28-c29a93cb92bb",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "به دیگران پاس ندهید",
      "سبقت نگیرید",
      "از این جاده استفاده نکنید",
    ],
    timestamp: 1660755861746,
    signImg: doNotPass,
  },
  {
    id: "22ae7d8d-8bba-4e62-9923-0f6ccb023324",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "از محصولات زراعتی مزرعه داران استفاده کنید",
      "امکان حضور وسیله نقلیه مزرعه داران",
      "در هوای گرم از نوشیدنی های زراعتی استفاده کنید",
    ],
    timestamp: 1660755879754,
    signImg: farmVehicle,
  },
  {
    id: "9d77cd6b-b03e-4087-b29a-1b3605f949db",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "از هدایات و دستورات افراد پرچم دار تبعیت کنید",
      "در مسابقه فوتبال از داور استفاده می شود",
      "ممکن است در جاده تصادف اتفاق افتاده باشد",
    ],
    timestamp: 1660755896498,
    signImg: flaggerSign,
  },
  {
    id: "ce0250bd-bfd0-4c1e-ab79-f9c84e8516a4",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "حداکثر سرعت ۲۵ مایل در ساعت است",
      "تنها در روزهای بیست و پنج هر ماه در این جاده تردد کنید",
      "علامت بزرگراه بین ایالتی ",
    ],
    timestamp: 1660755913531,
    signImg: interstateSing,
  },
  {
    id: "028d1364-891a-467a-b91f-b1b1d31fe168",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "به طرف راست بروید",
      "تا می توانید مواظب باشید",
      "جاده دو طرفه می شود",
    ],
    timestamp: 1660755933683,
    signImg: keepRightSign,
  },
  {
    id: "b8089021-1df8-4521-9fea-a8d8150b25c6",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "نشان دهنده حداکثر ارتفاع برای وسایل نقلیه می باشد",
      "نشان دهنده وجود پل در جاده است",
      "نشان دهنده حضور مقامات دولتی است",
    ],
    timestamp: 1660755951155,
    signImg: lowClearance,
  },
  {
    id: "0d30a069-9a7d-4ea7-b9b9-09e094fcf250",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "مواظب دزدان وسایل نقلیه باشید",
      "زندانی ها در حال تردد هستند",
      "کارگران در حال کار هستند",
    ],
    timestamp: 1660755968107,
    signImg: menAtWork,
  },
  {
    id: "8313537e-af11-4518-be7e-b2967f8ca797",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "امکان حضور ماشین های ورزشی در جاده می باشد",
      "علامت ادغام جاده می باشد",
      "مواظب حضور حیوانات در جاده باشید",
    ],
    timestamp: 1660755984300,
    signImg: mergeSign,
  },
  {
    id: "dd449dc5-4239-4650-8145-bc26e350368e",
    question: "این علامت به چه معنی است ",
    correctAnswer: 2,
    answers: [
      "رژیم غذایی درست باعث تناسب اندام می شود",
      "پل در جلو باریک می شود",
      "امکان ریزش پل وجود دارد",
    ],
    timestamp: 1660756004251,
    signImg: narrowBridge,
  },
  {
    id: "8135b1c7-8881-47a3-ad42-d84da5adb7ac",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "به طرف چپ گردش نکنید",
      "هوا مه آلود می باشد",
      "تا می توانید به عابرین پیاده احترام بگذارید",
    ],
    timestamp: 1660756027740,
    signImg: noLeftTurn,
  },
  {
    id: "47226131-82ce-4122-9348-38828d3c2502",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "در خانه خود پارک نکنید",
      "در این محل پارک نکنید",
      "اجازه دارید تا بصورت موقت در اینجا پارک کنید",
    ],
    timestamp: 1660756044140,
    signImg: noParking,
  },
  {
    id: "a1fa0d00-9150-4afa-aab1-98d6b200ca05",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "در این محل آشغال نریزید",
      "در این محدود سبقت نگیرید",
      "این محدوده فقط برای استفاده کامیون می باشد",
    ],
    timestamp: 1660756060349,
    signImg: noPassZone,
  },
  {
    id: "da732567-a3ef-4c91-b5a4-9432140954d3",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "برای حمل و نقل از کامیون استفاده نکنید",
      "برای کاهش آلودگی از ماشین های عمومی استفاده کنید",
      "کامیون ها از این مسیر تردد نمی توانند ",
    ],
    timestamp: 1660756080372,
    signImg: noTrucks,
  },
  {
    id: "9fe71207-8998-4bcc-94e1-c5edd009ce4d",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: ["یو تورن نکنید", "به چپ گردش نکنید", "به راست گردش نکنید"],
    timestamp: 1660756096893,
    signImg: noUTurn,
  },
  {
    id: "c32cc9ec-5967-4462-bf73-c11224a0f8a3",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "این جاده تنها برای تعقیب دزدان می باشد",
      "این جاده تنها برای استفاده از آمبولانس می باشد",
      "این جاده یک طرفه است",
    ],
    timestamp: 1660756113518,
    signImg: oneWay,
  },
  {
    id: "8d24ac1c-24e8-409d-a65c-4654077e8930",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "در وقت گردش به دو طرف خود نگاه کنید",
      "در جلو چراغ راهنمایی وجود دارد",
      "محل تردد عابرین پیاده ",
    ],
    timestamp: 1660756128414,
    signImg: pedesterianCrossing,
  },
  {
    id: "e104e4af-ecfc-46ad-974d-4969e1cc61d5",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "برای پاسپورت خود عکس مناسب بگیرید",
      "از عکس برداری در این محل خودداری کنید",
      "دوربین های عکس برداری از ترافیک ",
    ],
    timestamp: 1660756144694,
    signImg: photoEnforced,
  },
  {
    id: "5ffe7386-28ec-4765-939e-28383716e4fd",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "به روبرو خیره نشوید",
      "محل تردد قطار ",
      "تا می توانید مواظب اطراف تان باشید ",
    ],
    timestamp: 1660756172382,
    signImg: railRoadCrossing,
  },
  {
    id: "31d03e72-47b7-4bd8-a5cb-cbf2ff88d894",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "جاده باریک می شود",
      "جاده دو طرفه می شود",
      "مواظب رانندگان پرخاشگر باشید",
    ],
    timestamp: 1660756189094,
    signImg: roadNarrow,
  },
  {
    id: "c6aa953d-5aea-4fbe-9db8-fdc48a74327d",
    question: "این  علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "مواظب گیج شدن تان باشید",
      "در جلو میدان وجود دارد",
      "تا می توانید مواظب رانندگان حواس پرت باشید",
    ],
    timestamp: 1660756204398,
    signImg: roundAbout,
  },
  {
    id: "559315eb-717b-4135-b084-9a1c24d16d9f",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "در حال نزدیک شدن به مدرسه هستید",
      "در حال نزدیک شدن به بزرگراه هستید",
      "در حال نزدیک شدن به محل عابرین پیاده هستید",
    ],
    timestamp: 1660756221023,
    signImg: schoolZone,
  },
  {
    id: "1bca63ae-5032-446f-bac5-8a521dd7ea78",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "از رانندگی پرخاشگرانه خودداری کنید",
      "جاده لغزنده است",
      "مواظب رانندگان پرخاشگر باشید",
    ],
    timestamp: 1660756243103,
    signImg: slipperyRoad,
  },
  {
    id: "6ba7e55f-4619-44de-9b17-1e29e4bbb546",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "مراسم خاکسپاری در جریان است",
      "وسایل نقلیه کم سرعت",
      "مواظب عبور و مرور حیوانات ولگرد باشید",
    ],
    timestamp: 1660756260598,
    signImg: slowMovingVehicle,
  },
  {
    id: "23189d94-93f0-4413-8d60-a74acc500762",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "حداکثر تا چهل نفر در یک اتوبوس بنشینند",
      "حداکثر تا چهل سالگی گواهینامه رانندگی را دریافت کنید",
      "حداکثر سرعت مجاز ۴۰ مایل در ساعت می باشد",
    ],
    timestamp: 1660756278223,
    signImg: speedLimit,
  },
  {
    id: "02e318a1-f05c-4ca4-841c-3c6f266585be",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "به پلیس و آمبولانس احترام بگذارید",
      "در حال نزدیک شدن به چراغ راهنمای ترافیک هستید",
      "مواظب حداکثر سرعت خود باشید",
    ],
    timestamp: 1660756294984,
    signImg: trafficSignal,
  },
  {
    id: "77a45b31-c60c-43d8-9560-7a6e1de36b44",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "از رانندگی پرخاشگرانه بپرهیزید",
      "مواظب رانندگان پرخاشگر باشید",
      "جاده در ادامه دوطرفه می شود",
    ],
    timestamp: 1660756314527,
    signImg: twoWayAhead,
  },
  {
    id: "96246b8f-29ad-4ca0-8b87-7a9bc8774cad",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "این مسیر دارای مار می باشد",
      "مسیر پر پیچ و خم می شود",
      "مواظب حضور موانع در جاده باشید",
    ],
    timestamp: 1660756329088,
    signImg: windingRoad,
  },
  {
    id: "366e4841-3627-48f1-8e68-1d32c4f1c396",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "این مسیر اشتباه است",
      "در زندگی همیشه به راه راست بروید",
      "مواظب حیوانات ولگرد باشید",
    ],
    timestamp: 1660756346024,
    signImg: wrongWay,
  },
  {
    id: "3676c0cd-f31a-4976-bad4-43945383a304",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "به وسایل نقلیه دیگر حق تقدم بدهید",
      "مواظب موانع جاده باشید",
      "ممکن است در ادامه مسیر تصادف شده باشد",
    ],
    timestamp: 1660756362824,
    signImg: yieldSign,
  },
  {
    id: "37a45885-5def-4c36-bba9-6385c8d354ed",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "به چهار راهی نزدیک می شوید",
      "مواظب ماشین آمبولانس باشید",
      "به خط راه آهن نزدیک می شوید",
    ],
    timestamp: 1660840956741,
    signImg: approachIntersection
  },
  {
    id: "21e227cc-f506-4bd2-a50e-ab38f8a781d7",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "کامیون ها حق پارک در این منطقه را ندارند",
      "این خط مخصوص دوچرخه سواران است. پارک نکنید",
      "از خط مخصوص دوچرخه برانید",
    ],
    timestamp: 1660840976052,
    signImg: bikeLaneNoPark
  },
  {
    id: "8bb2e396-5106-4846-b5bd-780ffffe0cd4",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "جاده یک طرفه است",
      "گردش به چپ نکنید",
      "علامت پیچ در جاده می باشد",
    ],
    timestamp: 1660840991539,
    signImg: sharpTurn
  },
  {
    id: "4f21df45-390b-4046-9db4-b83b5e7767ff",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "به سراشیبی نزدیک می شوید ",
      "کامیون ها در این مسیر تردد نکنند",
      "کامیون ها در این جا پارک نکنند",
    ],
    timestamp: 1660841007284,
    signImg: approachingDip
  },
  {
    id: "22063d01-f172-4ccf-b173-9d6391d93d04",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "بخش ایمرجنسی بیمارستان در آن طرف جاده می باشد",
      "تنها در حالت ایمرجنسی حق توقف در این محل را دارید",
      "در زمان عبور از جلو ایمرجنسی بوق نزنید",
    ],
    timestamp: 1660841022694,
    signImg: stopOnlyEmergency
  },
  {
    id: "b08a8830-c765-4111-83e9-88d441ca4b2a",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "مزاحم کارگران روی جاده نشوید",
      "ختم کار در جاده ",
      "در هنگام عبور از کارگران روی جاده مواظب باشید",
    ],
    timestamp: 1660841039219,
    signImg: endRoadWork
  },
  {
    id: "48d0e862-8f40-4cd1-9048-93a2c4ffd1ae",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "ماشین ها از این جاده به بزرگراه ادغام می شوند",
      "مواظب ادغام ماشین های جدید به بزرگراه باشید",
      "این جاده فقط برای خروج از بزرگراه می باشد",
    ],
    timestamp: 1660841056287,
    signImg: exitOnly
  },
  {
    id: "4a0fd2ce-52a1-48c9-84d6-e661c1d3f2f8",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "علامت ایستگاه آتش نشانی است",
      "در هنگام عبور از جاده به ماشین آتش نشانی حق تقدم عبور بدهید",
      "مواظب رانندگان پرخاشگر باشید",
    ],
    timestamp: 1660841072822,
    signImg: fireStation
  },
  {
    id: "1cb660d8-2e86-4692-910a-ada91296e0ef",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "در هنگام رانندگی غذا نخورید",
      "برای رفع بی خوابی غذا بخورید",
      "این منطقه برای مغازه های خوراکی-رستورانت ها می باشد",
    ],
    timestamp: 1660841089141,
    signImg: foodSign
  },
  {
    id: "8d7d9e5f-c46c-4cbe-9cc0-5bd20efd5c76",
    question: " این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "از ماشین های هیبرید استفاده کنید",
      "این منطقه دارای پمپ بنزین می باشد",
      "لطفا از استعمال دخانیات در پمپ بنزین خودداری کنید",
    ],
    timestamp: 1660841102300,
    signImg: gasStation
  },
  {
    id: "8ad9ed25-c72b-474a-b633-3bceeb1ae7e4",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "در هنگام عبور از جلو بیمارستان بوق نزنید",
      "در این منطقه بیمارستان واقع شده است",
      "برای مواقع اضطراری از بیمارستان استفاده کنید",
    ],
    timestamp: 1660841117589,
    signImg: hospitalSign
  },
  {
    id: "91445402-b11d-4226-9e34-04c4d5478f5d",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "ماشین هایی که دو یا زیادتر از دو سرنشین دارند می توانند از این خط استفاده کنند",
      "با یک سرنشین از خانه خارج نشوید",
      "برای حفظ محیط زیست از ماشین های برقی استفاده کنید",
    ],
    timestamp: 1660841132021,
    signImg: hovSign
  },
  {
    id: "13a72bc6-776a-47ee-9c5b-cd18687cc5e6",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "مواظب رانندگان پرخاشگر باشید",
      "یک خط دیگر به جاده اضافه می شود",
      "در راه به ماشین های پولیس حق تقدم عبور بدهید",
    ],
    timestamp: 1660841146550,
    signImg: laneAdded
  },
  {
    id: "28b581ef-1541-4ad0-b692-1de5a3ba9c8e",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "در جلو بزرگراه قرار دارد",
      "مواظب رانندگان پرخاشگر باشید",
      "نشان دهنده حق حرکت هر خط جاده می باشد",
    ],
    timestamp: 1660841160956,
    signImg: laneControl
  },
  {
    id: "83192f7d-9bb6-4da0-9b2c-625d00f06c0a",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "ماشین های این خط حق دارند به صورت مستقیم حرکت کنند یا به چپ گردش کنند",
      "جاده یک طرفه است",
      "در جلو چراغ راهنما قرار دارد",
    ],
    timestamp: 1660841177444,
    signImg: leftOrStraight
  },
  {
    id: "8cf312c6-f1bc-4a8c-a825-fceeaa29f027",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "این مسیر تنها برای کامیون ها است",
      "باید به طرف راست یا چپ گردش کنید",
      "مسیر حرکت آمبولانس ها را نشان می دهد",
    ],
    timestamp: 1660841191780,
    signImg: leftOrRight
  },
  {
    id: "a42549c8-f3ee-461e-82bb-510a50091529",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "از محصولات لبنی استفاده کنید تا سلامت بمانید",
      "این علامت نشان دهنده حضور حیوانات اهلی می باشد",
      "به حیوانات اهلی غذا بدهید",
    ],
    timestamp: 1660841206973,
    signImg: liveStock
  },
  {
    id: "ae55fac0-d5d7-4d71-8299-4844b39e3e11",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "برای کاهش خطر رانندگی به اندازه کافی بخوابید",
      "بخاری را روشن کنید تا حین رانندگی نخوابید",
      "در اینجا می توانید جایی برای استراحت بیابید",
    ],
    timestamp: 1660841220972,
    signImg: lodging
  },
  {
    id: "ae895fa2-7691-4cfd-80b4-9d86cc0f9f89",
    question:
      " این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "یو تورن نکنید و یا به سمت چپ گردش نکنید",
      "مواظب رانندگان پرخاشگر باشید",
      "به سمت چپ گردش نکنید",
    ],
    timestamp: 1660841235531,
    signImg: noLeftUturn
  },
  {
    id: "d6f43668-aaca-412d-b35c-8bd05a60a533",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "به سمت چپ گردش کنید",
      "تنها به سمت راست رانده می توانید",
      "یو تورن نکنید",
    ],
    timestamp: 1660841249518,
    signImg: onlyRight
  },
  {
    id: "2988b6a9-d365-485a-8c35-35410c314ae3",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "در این منطقه آشغال نریزید",
      "به ماشین های آتش نشانی حق تقدم بدهید",
      "در جلو کار در جاده شروع می شود ",
    ],
    timestamp: 1660841262260,
    signImg: roadWorkAhead
  },
  {
    id: "a8a8ef86-05c8-423c-ba62-b9621431e2e1",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "به عابرین پیاده حق تقدم عبور بدهید",
      "عابرین پیاده با سرعت از مسیر عبور کنند",
      "مواظب رانندگان حواس پرت باشید",
    ],
    timestamp: 1660841279085,
    signImg: yieldPedestrian
  },

  {
    id: "44bb60f8-2939-4908-a1f6-a23d1a482602",
    question:
      "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "دوچرخه سواران می توانند از تمام جاده استفاده کنند",
      "دوچرخه سواران حق استفاده از تمام جاده را ندارند",
      "برای گردش به راست می توانید به خط دوچرخه داخل شوید",
    ],
    timestamp: 1660843588513,
    signImg: bikesMayUseFull
  },
  {
    id: "74a1cf2f-6bd1-41b8-9e3c-92f81ebdfdd3",
    question: "این علامت به چه معنی است",
    correctAnswer: 3,
    answers: [
      "اتوبوس حق توقف ندارد",
      "به اتوبوس حق تقدم عبور بدهید",
      "به ایستگاه اتوبوس نزدیک می شوید",
    ],
    timestamp: 1660843609876,
    signImg: approachingBusStation
  },
  {
    id: "f3157dfc-908a-4d37-a4c6-9aaa1a2b200f",
    question: " این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "در هنگام ادغام به بزرگراه با سرعت مناسب برانید",
      "بزرگراه تقسیم شده است",
      "مواظب رانندگان پرخاشگر باشید ",
    ],
    timestamp: 1660843628349,
    signImg: highwayDivided
  },
  {
    id: "34f47f8d-2829-4af6-805b-d6046b56cea7",
    question: "این علامت به چه معنی است",
    correctAnswer: 2,
    answers: [
      "از ماشین های هیبرید استفاده کنید",
      "ایستگاه چارج ماشین های برقی است ",
      "علامت پمپ بنزین می باشد",
    ],
    timestamp: 1660843645396,
    signImg: evStation
  },
  {
    id: "ba9a576a-09c8-4942-9055-e9cf13ef6edd",
    question: "این علامت به چه معنی است",
    correctAnswer: 1,
    answers: [
      "علامت پیچ 180 درجه می باشد",
      "یو تورن نکنید",
      "حق یو تورن دارید",
    ],
    timestamp: 1660843659148,
    signImg: fullTurn
  },
];

export default signs;
