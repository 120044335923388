import React from "react";
import { Link } from "react-router-dom";
import SVGFile from "./SVGFile";

const Header = () => {
  return (
    <div
      className="w-screen h-screen bg-[#f1f5f9] -z-10 pt-8 md:pt-24"
    >
      <div className="grid text-neutral-900 max-w-[1540px] text-center items-center w-full mx-auto p-4">
        <div className="grid">
          <h1 className="mb-4 text-xl font-semibold leading-8 uppercase md:text-2xl lg:text-3xl xlg:text-4xl">
            تست هایی که شما را برای امتحان پرمیت و سیتیزن شیپ آماده می سازند
          </h1>

          <p className="mb-8 md:text-[20px]">
            با گذراندن این تست ها شما آمادگی بیشتر بدست آورده و شانس موفقیت تان
            افزایش چشمگیر می یابد
          </p>

          <Link
            to="/tests"
            className="px-6 py-2 lg:px-20 md:py-4 bg-teal-800 text-white shadow-md rounded-md font-semibold text-xl md:w-[30%] md:block md:justify-self-center"
          >
            صفحه تست ها
          </Link>

          <div className="mt-14 md:h-[60vh]">
            <SVGFile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
