import React from 'react'
import { Link } from 'react-router-dom'
import NotFoundSvg from '../components/notfoundpage/NotFoundSvg'
const NotFound = () => {
  return (
    <div className='w-screen max-w-[1540px] mx-auto pt-8 md:pt-24 my-8 md:my-24'>
        <div className='flex flex-col items-center justify-center gap-8 md:gap-24'>
            <h2 className='text-2xl font-bold md:text-3xl lg:text-4xl'>متاسفیم</h2>
            <h3 className='text-3xl font-bold md:text-4xl lg:text-5xl'>این صفحه موجود نیست</h3>
            <p className='px-8 py-2 bg-[#115e59] text-white text-xl font-bold rounded-md shadow-md'>
                <Link to='/'>برگرد به صفحه اصلی</Link>
            </p>
            <div className='max-h-[1000px]'>
            <NotFoundSvg />
            </div>
        </div>
    </div>
  )
}

export default NotFound