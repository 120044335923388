import paginate from "../../utils/paginate";
// import axios from 'axios'

// const BASE_URL = 'http://localhost:5000/api/tests/'

// // Get all general tests

// const getAllGeneral = async() => {
//     const response = await axios.get(BASE_URL + 'general')
//     return response.data
// }

// // Get all quizes

// const getAllQuizes = async() => {
//     const response = await axios.get(BASE_URL + 'quizes')
//     return response.data
// }

// // Get all sigs

// const getAllSigns = async() => {
//     const response = await axios.get(BASE_URL + 'signs')
//     return response.data
// }

// // Get all citizenship

// const getAllCitizenship = async() => {
//     const response = await axios.get(BASE_URL + 'citizenship')
//     return response.data
// }
// const testServices = {getAllCitizenship, getAllQuizes, getAllSigns, getAllGeneral}

// export default testServices

const getAllTests = (testName) => {
  const data = paginate(20, testName);
  return data;
};

export default getAllTests