
const  citizenships = [
    {
      id: "e1877a44-9e9d-4dcb-a816-7ac8d2ada21a",
      question: "قانون اصلی در ایالات متحده آمریکا چیست",
      answers: [
        "قانون اساسی "
      ],
      timestamps: 1660775876756
    },
    {
      id: "c1d9de94-b0f2-421b-ad4c-ba31f7784ca6",
      question: "قانون اساسی چه کاری انجام می دهد",
      answers: [
        "تشکیل دولت",
        "تعیین وظایف دولت",
        "حمایت از حقوق اساسی شهروندان ایالات متحده آمریکا"
      ],
      timestamps: 1660776030170
    },
    {
      id: "e1c78786-ec8d-4141-a1e2-decc6cd7b20d",
      question: "واژه حکومت مردم بر مردم در سه کلمه اول قانون اساسی ایالات متحده آمریکا ذکر شده است. آن سه کلمه را بگویید",
      answers: [
        "ما مردم آمریکا"
      ],
      timestamps: 1660776038691
    },
    {
      id: "8108ffe4-e6c0-4f86-91c1-1ffb24f22db1",
      question: "متمم در قانون اساسی چیست؟ ",
      answers: [
        "تغییر در قانون اساسی ",
        "ضمیمه کردن یا اضافه ساختن به قانون اساسی"
      ],
      timestamps: 1660776052379
    },
    {
      id: "6cbc8880-f76a-4831-9e08-f81d90ef40f3",
      question: "ده متمم اول قانون اساسی ایالات متحده آمریکا را به چه نام می شناسند",
      answers: [
        "متمم یا لایحه حقوق"
      ],
      timestamps: 1660776086049
    },
    {
      id: "633c114d-3159-48a2-a447-cf937f7c991c",
      question: "یکی از آزادی های شهروندان که در متمم یا لایحه اول قانون اساسی ایالات متحده آمده را نام بگیرید",
      answers: [
        "آزادی بیان",
        "آزادی مذهب",
        " آزادی تجمع",
        "آزادی مطبوعات",
        "آزادی دادخواست به دولت "
      ],
      timestamps: 1660776120674
    },
    {
      id: "e2db526a-9c23-4870-8d80-f8fcd09ea50f",
      question: "قانون اساسی آمریکا دارای چند متمم یا لایحه می باشد",
      answers: [
        "بیست و هفت متمم یا لایحه"
      ],
      timestamps: 1660776131633
    },
    {
      id: "f879d525-ab7f-4676-b672-98b32b4606af",
      question: "اعلامیه استقلال ایالات متحده آمریکا چه نتیجه ای دربر داشت",
      answers: [
        "اعلان آزادی ایالات متحده (از بریتانیای کبیر)",
        "اعلام عمومی آزادی ایالات متحده (از بریتانیای کبیر)",
        "اعلام استقلال ایالات متحده (از بریتانیای کبیر)"
      ],
      timestamps: 1660776147977
    },
    {
      id: "4f6a707b-a7a4-4aa4-93b2-94bb1b83ea08",
      question: "دو حق که در اعلامیه آزادی آمده است را بنویسید",
      answers: [
        "حق زندگی",
        "حق آزادی",
        " حق دسترسی به خوشبختی"
      ],
      timestamps: 1660776167786
    },
    {
      id: "eadb0bf4-5d9d-44db-bfa6-82bc54a9aab7",
      question: "آزادی دین یا مذهب به چه معنی می باشد",
      answers: [
        "شهروندان آمریکا می توانند تعالیم دینی یا مذهبی خود را انجام دهند و یا از هر نوع تعالیم دینی دوری کنند"
      ],
      timestamps: 1660776175547
    },
    {
      id: "b5f32181-464d-449b-94d9-5074b4e828aa",
      question: "سیستم اقتصادی موجود در ایالات متحده آمریکا را نام بگیرید",
      answers: [
        "اقتصاد سرمایه داری یا کاپیتالیسیتی",
        " اقتصاد بازار"
      ],
      timestamps: 1660776196208
    },
    {
      id: "c297fb4a-d9b0-4f5c-ab22-cd82e1c5c022",
      question: "معنی حکومت قانون در ایالات متحده چه می باشد",
      answers: [
        "یعنی همه باید از قانون پیروی کنند",
        "رهبران باید از قانون پیروی کنند",
        "دولت باید از قانون پیروی کند",
        "هیچ کس فراتر از قانون نیست"
      ],
      timestamps: 1660776235848
    },
    {
      id: "229d31c4-cce5-46d3-962d-57ee72f1bbfb",
      question: "یکی از سه قوه موجود در ایالات متحده آمریکا را نام ببرید",
      answers: [
        "قوه مقننه یا کانگرس",
        "قوه مجریه یا ریاست جمهوری",
        "قوه قضاییه یا دادگاه ها"
      ],
      timestamps: 1660776270080
    },
    {
      id: "d21d3c3a-3fea-4a1a-a37d-bfd69f25151f",
      question: "چه چیزی باعث می شود تا یکی از سه قوه از قدرت زیادی برخوردار نشود",
      answers: [
        "کنترل و بررسی های قوا",
        "تفکیک قوا"
      ],
      timestamps: 1660776285361
    },
    {
      id: "7fa23396-fd34-4d53-ab57-d03722332add",
      question: "چه کسی در راس قوه مجریه قرار دارد",
      answers: [
        "رییس جمهور ایالات متحده آمریکا"
      ],
      timestamps: 1660776293752
    },
    {
      id: "b7237104-f705-420a-a066-0a3fe87ebe44",
      question: "چه فرد یا نهادی قوانین فدرال را وضع می نماید",
      answers: [
        "کانگرس که شامل مجلس نمایندگان و سنا می باشند",
        " بخش قانونگذاری ایالات متحده آمریکا"
      ],
      timestamps: 1660776306440
    },
    {
      id: "58adbd54-1a7a-4273-9195-037dc1b0c893",
      question: "دو بخش موجود در کانگرس را نام ببرید",
      answers: [
        "مجلس سنا و مجلس نمایندگان"
      ],
      timestamps: 1660776469848
    },
    {
      id: "bca5e349-4953-40e2-bf0f-b4e5a3951b78",
      question: "چند سناتور در مجلس سنا ایالات متحده آمریکا حضور دارند",
      answers: [
        "صد سناتور"
      ],
      timestamps: 1660776476712
    },
    {
      id: "c9cab3c6-f6f7-4dc1-88fa-fa29d910b79d",
      question: "دوره نمایندگی هر سناتور مجلس سنا چند سال است",
      answers: [
        "شش سال"
      ],
      timestamps: 1660776483729
    },
    {
      id: "24e583c3-03b6-4c89-bed7-01d16b2199ed",
      question: "نام یکی از سناتورهای ایالت خودتان را بگویید",
      answers: [
        "در کالیفورنیا:‌ خانم دیان فینستین و آقای الکس پدیلا"
      ],
      timestamps: 1660776497384
    },
    {
      id: "52d89bed-34c3-4959-acc1-a9b317143349",
      question: "مجلس نمایندگان چند عضو رای دهنده دارد",
      answers: [
        "چهار صد و سی و پنج عضو"
      ],
      timestamps: 1660776503115
    },
    {
      id: "fbcc2338-e5e3-439f-a17c-03a28be059e1",
      question: "دوره نمایندگی هر عضو مجلس نمایندگان چند سال است",
      answers: [
        "دو سال"
      ],
      timestamps: 1660776509145
    },
    {
      id: "57915dc9-e058-4759-956e-32b64934031b",
      question: "یکی از نمایندگان ایالت خودتان را نام بگیرید",
      answers: [
        "در کالیفورنیا: جرد هافمن، جان گارامندی، تام مک کلینتوک، مایک تامپسون، نانسی پلوسی، "
      ],
      timestamps: 1660776516210
    },
    {
      id: "7b0392c7-3517-4866-bb0d-557dd51f6c90",
      question: "سناتور نماینده چه بخشی از مردم است",
      answers: [
        "نماینده همه مردم ایالات متحده آمریکا"
      ],
      timestamps: 1660776522681
    },
    {
      id: "76a6268c-e30e-4a2f-8457-579976ece703",
      question: "چرا برخی ایالت های دارای سناتورهای بیشتری هستند",
      answers: [
        "بدلیل جمعیت آن ایالات و اینکه جمعیت آنها نسبت به سایر ایالات بیشتر است"
      ],
      timestamps: 1660776530328
    },
    {
      id: "2a5fa5b1-9c3e-4fb1-8055-ea45121856ab",
      question: "ما رئیس جمهور ایالات متحده آمریکا را برای چند سال انتخاب می کنیم",
      answers: [
        "برای چهار سال"
      ],
      timestamps: 1660776538922
    },
    {
      id: "c6ba21ae-e23e-4dd4-9957-bb865f77054c",
      question: "نام رئیس جمهور فعلی و برحال ایالات متحده آمریکا را بگویید",
      answers: [
        "جوزف آر بایدن",
        "جو بایدن"
      ],
      timestamps: 1660776554872
    },
    {
      id: "c4d64b85-b5bb-43fb-b311-72713740547f",
      question: "نام معاون رئیس جمهور فعلی و برحال ایالات متحده آمریکا را بگویید",
      answers: [
        "کامالا دی هریس",
        "کامالا هریس"
      ],
      timestamps: 1660776569841
    },
    {
      id: "903a77e2-a290-4073-b3bd-b1d378cf29fc",
      question: "اگر رئیس جمهوری قادر به ادامه خدمت نباشد چه کسی به جای وی رئیس جمهور می شود",
      answers: [
        "معاون رئیس جمهور"
      ],
      timestamps: 1660776577472
    },
    {
      id: "2092803b-316d-4b4a-956a-0951c00dfbcb",
      question: "اگر رئیس جمهور و معاون وی هر دو قادر به ادامه خدمت نباشند چه کسی رئیس جمهور می شود",
      answers: [
        "سخنگوی مجلس نمایندگان"
      ],
      timestamps: 1660776583200
    },
    {
      id: "fd06b0d2-a1ca-409c-963a-b8380cd19306",
      question: "چه کسی فرمانده کل قوای ایالات متحده آمریکا می باشد",
      answers: [
        "رئیس جمهور ایالات متحده آمریکا"
      ],
      timestamps: 1660776589760
    },
    {
      id: "edb2aa9a-07e0-4453-b5b4-81529acb4146",
      question: "امضای چه کسی باعث تبدیل لوایح یا متمم به قانون می شود",
      answers: [
        "رئیس جمهور"
      ],
      timestamps: 1660776596423
    },
    {
      id: "8ef02058-844a-45da-ae6f-1d7dd0fa5a9c",
      question: "چه کسی حق وتو یا رد کردن لوایح را دارد",
      answers: [
        "رئیس جمهور"
      ],
      timestamps: 1660776602800
    },
    {
      id: "698ceb87-28e5-4cfa-ae4d-60c3b095ca9a",
      question: "وظیفه کابینه رئیس جمهور چیست",
      answers: [
        "ارائه مشاوره به رئیس جمهور"
      ],
      timestamps: 1660776608849
    },
    {
      id: "351340f1-9baa-4358-9ada-2eed6615cbee",
      question: "دو مقام در سطح کابینه را نام بگیرید",
      answers: [
        "وزیر کشاورزی",
        "‌ وزیر بازرگانی",
        " وزیر دفاع",
        " وزیر آموزش و پرورش",
        " وزیر نیرو",
        "وزیر بهداشت و خدمات انسانی",
        " وزیر امنیت کشور",
        " وزیر مسکن و شهرسازی",
        " وزیر داخله یا وزیر کشور",
        " وزیر امور خارجه",
        " وزیر حمل و نقل",
        "وزیر خزانه داری",
        "وزیر کار",
        " دادستان کل",
        " معاون ریاست جمهوری"
      ],
      timestamps: 1660776695089
    },
    {
      id: "11908860-f960-41da-9b15-25b93c30f999",
      question: "شعبه قضایی دارای چه وظایفی می باشد",
      answers: [
        "مرور قوانین",
        "ارائه وضاحت در مورد قوانین",
        "حل مشاجرات و اختلافات",
        "تصمیم گیری در مورد اینکه آیا قانونی مخالف قانون اساسی می باشد یا خیر"
      ],
      timestamps: 1660776711114
    },
    {
      id: "ebefb629-2ffa-44ac-895b-ef345180421c",
      question: "بالاترین دادگاه ایالات متحده آمریکا را نام بگیرید",
      answers: [
        "دیوان عالی کشور"
      ],
      timestamps: 1660776717511
    },
    {
      id: "2f105fb7-4561-4a0f-9c6e-9153369071e6",
      question: "تعداد قضات دیوان عالی کشور چند نفر می باشد",
      answers: [
        "نه نفر"
      ],
      timestamps: 1660776723040
    },
    {
      id: "652a3aea-794e-4eed-9973-068642c69305",
      question: "در حال حاضر چه کسی رئیس دیوان عالی ایالات متحده آمریکا است",
      answers: [
        "جان رابرتز "
      ],
      timestamps: 1660776741442
    },
    {
      id: "66695ad8-ef7c-43a2-9216-a7f2af155c1b",
      question: "بر طبق قانون اساسی ما، برخی از قدرت ها متعلق به دولت فدرال ایالات متحده آمریکا می باشد. یکی از این قدرت ها را نام بگیرید",
      answers: [
        "قدرت چاپ پول",
        " اعلام جنگ",
        "ایجاد ارتش",
        " انعقاد معاهدات و پیمان ها"
      ],
      timestamps: 1660776761073
    },
    {
      id: "581c1515-7054-4b5e-b14e-7999944eed86",
      question: "بر طبق قانون اساسی، برخی از اختیارات متعلق به دولت ایالتی می باشد. یکی از این اختیارات را نام بگیرید",
      answers: [
        "تامین مدارس و امکان تحصیل",
        " تامین امنیت (بواسطه پلیس)",
        " تامین ایمنی (بواسطه آتش نشانی)",
        " صدور گواهینامه رانندگی",
        "منطقه بندی و بهره برداری ملکی"
      ],
      timestamps: 1660776778832
    },
    {
      id: "e99546b3-e3df-452d-9a2e-88f187596dac",
      question: "نام فرماندار ایالت تان را بگویید",
      answers: [
        "در کالیفورنیا: گاوین نیوسم"
      ],
      timestamps: 1660776787697
    },
    {
      id: "eb7bc59c-e525-4bd6-b463-09255f602a38",
      question: "پایتخت ایالت تان را نام ببرید",
      answers: [
        "در کالیفورنیا: ساکرامنتو"
      ],
      timestamps: 1660776793923
    },
    {
      id: "f1011acb-775e-426d-b91e-c5108f1ca791",
      question: "دو حزب سیاسی اصلی در ایالات متحده آمریکا را نام ببرید",
      answers: [
        "حزب دموکرات ",
        " حزب جمهوری خواه"
      ],
      timestamps: 1660776806936
    },
    {
      id: "7f3bc149-dcfa-4428-9b9b-25b0c0085826",
      question: "حزب سیاسی رئیس جمهوری فعلی چیست",
      answers: [
        "حزب دموکرات"
      ],
      timestamps: 1660776815488
    },
    {
      id: "d9e95e47-00b6-4b3d-b47c-9351b0ffad03",
      question: "نام سخنگوی مجلس نمایندگان را بگویید",
      answers: [
        "خانم نانسی پلوسی"
      ],
      timestamps: 1660776821720
    },
    {
      id: "05c999a9-c410-4452-80f0-9672b1a214fc",
      question: "چهار متمم قانون اساسی در مورد کسانی است که حق رای دارند. یکی از این چهار متمم را نام بگیرید",
      answers: [
        "شهروندان هیجده سال و بالاتر حق رای دارند",
        "برای رای دادن نیازی به پرداخت مالیات نمی باشد",
        "هر شهروند بدون در نظر داشت جنسیت حق رای دارد",
        "هر شهروند مرد بدون در نظر داشت نژاد حق رای دارد"
      ],
      timestamps: 1660776838961
    },
    {
      id: "19640a0b-ccc3-4a86-94e4-95be20c72a2f",
      question: "یکی از مسئولیت هایی که فقط بر عهده شهروندان ایالات متحده آمریکا هست را نام ببرید",
      answers: [
        "انجام وظیفه به عنوان عضوی از هیئت منصفه ",
        "رای دادن در انتخابات فدرال"
      ],
      timestamps: 1660776854952
    },
    {
      id: "9327260c-68a0-4975-8369-f50fd82a8e90",
      question: "یکی از حقوقی که تنها شامل حال شهروندان ایالات متحده آمریکا می شود را نام ببرید",
      answers: [
        "رای دادن در انتخابات فدرال",
        "کاندیداتوری برای پست ها و مقام های فدرالی"
      ],
      timestamps: 1660776863504
    },
    {
      id: "9472dde9-1fc0-4fcc-95cf-339ebc27e753",
      question: "دو حق از حقوقی که شامل تمامی ساکنین ایالات متحده آمریکا می شود را نام ببرید",
      answers: [
        "آزادی بیان",
        "آزادی سخنرانی",
        "آزادی تجمع",
        "آزادی دادخواهی از دولت",
        "ازادی عبادت",
        "آزادی حمل اسلحه"
      ],
      timestamps: 1660776884281
    },
    {
      id: "0ef067bc-7869-412f-bd59-14b3b3e17690",
      question: "وقتی سوگند وفاداری یاد می کنیم، در واقع از از چه چیزی حمایت می کنیم",
      answers: [
        "از ایالات متحده آمریکا",
        "از پرچم ایالات متحده آمریکا"
      ],
      timestamps: 1660776895904
    },
    {
      id: "7ea02b99-134f-4861-8539-6bedf8545dfa",
      question: "وقتی شهروند ایالات متحده آمریکا شدید، چه سوگندی یاد می کنید",
      answers: [
        "عدم وفاداری به سایر کشورها",
        "از قانون اساسی و قوانین ایالات متحده آمریکا پشتیبانی یا دفاع کنیم",
        "از قوانین ایالات متحده آمریکا پیروی کنیم",
        "در صورت لزوم، در ارتش آمریکا خدمت کنیم",
        "در صورت لزوم، خدمات ملی انجام دهیم ",
        "به ایالات متحده آمریکا وفادار باشیم"
      ],
      timestamps: 1660776917202
    },
    {
      id: "5ab8cdd9-0c62-42a8-bce6-53e6dadc8f42",
      question: "شهروندان آمریکا در چه سن و سالی قادر به رای دادن هستند",
      answers: [
        "هیجده سال یا بالاتر"
      ],
      timestamps: 1660776924225
    },
    {
      id: "1683741a-c84c-47be-a728-feb5f3b10e95",
      question: "دو مورد را نام ببرید که شهروندان ایالات متحده آمریکا می توانند در سیستم سیاسی کشورشان مشارکت کنند",
      answers: [
        "رای دادن",
        "پیوستن به یک حزب سیاسی",
        "به یک کمپین بپیوندند",
        "به یک گروه مدنی بپیوندند",
        "به یک گروه مردمی بپیوندند",
        "نظرشان را در مورد مسئله ای به سمع یک مقام منتخب برسانند",
        "با سناتورها و نمایندگان مجلس به تماس شوند",
        "حمایت و عدم حمایت شان را برای یک مورد سیاسی اعلام کنند",
        "برای یک روزنامه مطلب بنویسند",
        "نامزد انتخابات شوند"
      ],
      timestamps: 1660776954425
    },
    {
      id: "d1105345-b978-4318-a083-140d50a77f64",
      question: "آخرین مهلت برای اراءه فورم های مالیات بر درآمد فدرال چیست",
      answers: [
        "پانزدهم آپریل"
      ],
      timestamps: 1660776962187
    },
    {
      id: "7e695df8-e965-48e9-a36b-fc48666ba0b2",
      question: "چه زمانی باید تمام شهروندان مرد برای خدمت نظام نام نویسی کنند",
      answers: [
        "در هیجده سالگی",
        "در بین هیجده تا بیست و شش سالگی"
      ],
      timestamps: 1660777003731
    },
    {
      id: "0999cdc2-942c-45a7-bd8b-c5abd713e9ec",
      question: "یکی از دلایلی که مستعمره نشینان به ایالات متحده آمریکا آمدند را نام بگیرید",
      answers: [
        "آزادی",
        "آزادی سیاسی",
        "آزادی دینی یا مذهبی",
        "فرصت های اقتصادی",
        "امکان انجام تعالیم دینی یا مذهبی",
        "فرار از شکنجه و آزار"
      ],
      timestamps: 1660777024225
    },
    {
      id: "b2727c7d-f5bd-452f-aa56-0d3ca43a1164",
      question: "چه کسانی قبل از ورود اروپایی ها در سرزمین آمریکا زندگی می کردند",
      answers: [
        "بومیان آمریکا",
        "سرخپوست های آمریکایی"
      ],
      timestamps: 1660777033713
    },
    {
      id: "ad59c548-e592-452d-aedf-cbf98442fc05",
      question: "چه کسانی را بعنوان برده به آمریکا برده و آنجا فروختند",
      answers: [
        "آفریقایی ها ",
        "مردم آفریقا"
      ],
      timestamps: 1660777044055
    },
    {
      id: "721ca54f-bafd-4f3b-8d3b-23ae9e459ef2",
      question: "چرا مستعمره نشینان با بریتانیایی ها وارد جنگ شدند",
      answers: [
        "به خاطر مالیات سنگین (پرداخت مالیات بدون داشتن نماینده ای از طرف مستعمره نشینان)",
        "به خاطر اینکه سربازان ارتش بریتانیا در خانه مستعمره نشینان می ماندند (اقامت شبانه روزی)",
        "به خاطر اینکه مستعمره نشینان دولت خود مختار نداشتند"
      ],
      timestamps: 1660777055536
    },
    {
      id: "12fa3c2b-a596-49ed-8c9b-ed82043453d3",
      question: "چه کسی اعلامیه استقلال ایالات متحده آمریکا را نوشت",
      answers: [
        "توماس جفرسون"
      ],
      timestamps: 1660777061832
    },
    {
      id: "f828c445-e6f0-40ee-a791-5b4433917c6b",
      question: "اعلامیه استقلال ایالات متحده آمریکا در چه تاریخی تصویب شد",
      answers: [
        "در چهارم جولای هزار و هفتصد و هفتادو شش"
      ],
      timestamps: 1660777068984
    },
    {
      id: "a8adb250-5f3e-45b0-858a-4ceca2353c45",
      question: "سه ایالت از سیزده ایالتی اصلی ایالات متحده آمریکا در آن زمان را نام ببرید",
      answers: [
        "نیوهمپشایر",
        "ماساچوست",
        "رودآیلند",
        "کانتیکت",
        "نیویورک",
        "نیوجرسی",
        "پنسیلوانیا",
        "دلاور",
        "مریلند",
        "ویرجینیا",
        "کارولینای شمالی",
        "کارولینای جنوبی",
        "جورجیا"
      ],
      timestamps: 1660777111532
    },
    {
      id: "38b43572-9093-46fa-9507-89f976c958c7",
      question: "در اولین گردهمایی قانون اساسی چه اتفاقی افتاد",
      answers: [
        "قانون اساسی نوشته شد",
        "پدران موسس قانون اساسی را نوشتند"
      ],
      timestamps: 1660777120532
    },
    {
      id: "583e940a-8a46-4c30-94b6-f966f6a4a4a4",
      question: "قانون اساسی چه زمانی نوشته شد",
      answers: [
        "در سال هزار هفتصد و هشتاد و هفت"
      ],
      timestamps: 1660777127476
    },
    {
      id: "3a67d6c9-4a22-4381-80d1-dba82ddd60e3",
      question: "روزنامه های فدرالیست از تصویب قانون اساسی ایالات متحده آمریکا حمایت کردند. نام یکی از این نویسندگان را بگویید",
      answers: [
        "جیمز مدیسون",
        "الکساندر همیلتون",
        "جان جی",
        "پابلیوس"
      ],
      timestamps: 1660777142208
    },
    {
      id: "f8ab2d61-a7c0-488c-8387-ba9451a77116",
      question: "یکی از مواردی که بنجامین فرانکلین به خاطر آن مشهور است را بگویید",
      answers: [
        "دیپلومات ایالات متحده آمریکا",
        "مسن ترین عضو در اولین گردهمایی قانون اساسی بود",
        "اولین وزیر پست آمریکا بود",
        "نویسنده کتاب تقویم پور ریچارد بود",
        "موسس اولین کتابخانه رایگان بود"
      ],
      timestamps: 1660777161609
    },
    {
      id: "0829bc09-9b57-49d8-9756-300dc7fbf569",
      question: "چه فردی به عنوان پدر ایالات متحده آمریکا یاد می شود",
      answers: [
        "جورج واشینگتن"
      ],
      timestamps: 1660777168296
    },
    {
      id: "351da0c0-2968-4ed8-9cf6-03f1664711a6",
      question: "اولین رئیس جمهور ایالات متحده آمریکا کیست",
      answers: [
        "جورج واشینگتن"
      ],
      timestamps: 1660777174120
    },
    {
      id: "26ea47de-64fa-4814-95f6-29a77505d973",
      question: "در سال 1803، ایالات متحده کدام سرزمین را از فرانسه خریداری نمود",
      answers: [
        "سرزمین لوئیزیانا",
        "قلمرو لوئیزیانا"
      ],
      timestamps: 1660777183960
    },
    {
      id: "3a7b0a97-e6ce-491a-9279-d25dc457f8fc",
      question: "یکی از جنگ های صده 1800 ایالات متحده آمریکا را نام ببرید",
      answers: [
        "جنگ 1812",
        "جنگ مکزیک و آمریکا",
        "جنگ داخلی",
        "جنگ اسپانیا و آمریکا"
      ],
      timestamps: 1660777197280
    },
    {
      id: "aa8b6740-d2a9-412f-8a24-4b2b2e085c6b",
      question: "جنگی که بین ایالات شمالی و ایالات جنوبی رخ داد را نام بگیرید",
      answers: [
        "جنگ داخلی",
        "جنگ بین ایالات"
      ],
      timestamps: 1660777205353
    },
    {
      id: "88233eb6-dc11-4c8c-aaff-a652115a9c5f",
      question: "یکی از مشکلات که باعث جنگ داخلی شد را نام ببرید",
      answers: [
        "برده داری",
        "دلایل اقتصادی",
        "حقوق ایالت ها"
      ],
      timestamps: 1660777217672
    },
    {
      id: "4d3e043e-e992-4bdd-934f-23250e6a3294",
      question: "یکی از دستاوردهای مهم آبراهام لینکلن را نام ببرید",
      answers: [
        "ازادی برده ها",
        "نجات اتحادیه",
        "رهبری ایالات متحده آمریکا در زمان جنگ داخلی"
      ],
      timestamps: 1660777230768
    },
    {
      id: "b232baad-3f29-4e01-b1c7-d8248c2d69f5",
      question: "اعلامیه رهایی یا آزادی باعث چه دستاوردهایی شد",
      answers: [
        "بردگان را آزاد کرد",
        "آزادی برده های ایالات جنوبی"
      ],
      timestamps: 1660777241345
    },
    {
      id: "65aa9446-df55-4e13-a6ff-fadd0798ec8d",
      question: "سوزان آنتونی چه کار کرد",
      answers: [
        "کسی بود که برای آزادی حقوق زنان جنگید",
        "کسی بود که برای حقوق مدنی جنگید"
      ],
      timestamps: 1660777250400
    },
    {
      id: "65d31ddf-fa0a-4c5c-80bc-938575b49529",
      question: "یکی از جنگ هایی که ایالات متحده در صده 1900 داشت را بگویید",
      answers: [
        "جنگ جهانی اول",
        "جنگ جهانی دوم",
        "جنگ کره",
        "جنگ ویتنام",
        "جنگ خلیج فارس"
      ],
      timestamps: 1660777269001
    },
    {
      id: "5353637a-8a3a-4f5c-a47e-5b0d62e5d349",
      question: "چه کسی در زمان جنگ جهانی اول رئیس جمهور ایالات متحده آمریکا بود",
      answers: [
        "وودرو ویلسون"
      ],
      timestamps: 1660777275505
    },
    {
      id: "5cd4cdd0-08fb-4790-a24e-94f53a7cdda2",
      question: "چه کسی در زمان رکود اقتصادی بزرگ و جنگ جهانی دوم رئیس جمهور ایالات متحده آمریکا بود",
      answers: [
        "فرانکلین روزولت"
      ],
      timestamps: 1660777282721
    },
    {
      id: "5cf98ef1-8988-46ef-a94d-8a56b14f146d",
      question: "در جنگ جهانی دوم، ایالات متحده آمریکا با کدام کشورها در حال جنگ بود",
      answers: [
        "ژاپن ",
        "آلمان",
        "ایتالیا"
      ],
      timestamps: 1660777295417
    },
    {
      id: "762164be-3954-4b0a-b53c-1edffbc49d02",
      question: "قبل از اینکه جنرال آیزنهاور رئیس جمهور ایالات متحده آمریکا شود در یکی از جنگ ها در ارتش آمریکا بحیث جنرال ایفای وظیفه می نمود. نام آن جنگ را بگویید",
      answers: [
        "جنگ جهانی دوم"
      ],
      timestamps: 1660777303209
    },
    {
      id: "3de43ba2-3b76-4699-b351-e7feb0d031b9",
      question: "در دوران جنگ سرد، مهمترین نگرانی ایالات متحده آمریکا چی بود",
      answers: [
        "کمونیزم"
      ],
      timestamps: 1660777311033
    },
    {
      id: "77c0f705-734c-45b1-a1ac-a53036a3c1a2",
      question: "کدام جنبش ها سعی در پایان دادن به تبعیض نژادی داشت",
      answers: [
        "جنبش حقوق مدنی"
      ],
      timestamps: 1660777316697
    },
    {
      id: "e5d73ffe-2f93-4c8c-8179-929dcd6b0ed4",
      question: "مارتین لوترکینگ چه کرد",
      answers: [
        "برای حقوق مدنی جنگید",
        "برای برابری همه آمریکایی ها فعالیت می نمود"
      ],
      timestamps: 1660777325233
    },
    {
      id: "16d1bfd8-7635-4d04-80fe-3e57b6f4f09f",
      question: "چه واقعه مهمی در تاریخ یازده سپتامبر 2001 در ایالات متحده آمریکا رخ داد",
      answers: [
        "تروریست ها به ایالات متحده آمریکا حمله کردند"
      ],
      timestamps: 1660777331706
    },
    {
      id: "5324276c-29c1-4df7-8c8a-b1cf3c4522e5",
      question: "یکی از قبایل سرخ پوست آمریکایی در ایالات متحده را نام ببرید",
      answers: [
        "چروکی",
        "ناجا",
        "سیوکس",
        "چیپوا",
        "چوکتاو",
        "پوبلو",
        "آپاچی",
        "ایروکوئه",
        "نهر",
        "بلک فیت",
        "سمی نول شاین",
        "اراواک",
        "موهیگن",
        "اوران اونیدا",
        "لاکوتا",
        "کرو",
        "تیتان",
        "هوپی ",
        "اینویت"
      ],
      timestamps: 1660777401652
    },
    {
      id: "4204d416-1d5d-44b0-893c-193d62532f92",
      question: "یکی از دو رودخانه طولانی ایالات متحده آمریکا را نام ببرید",
      answers: [
        "رودخانه میسوری",
        "رودخانه می سی سی پی"
      ],
      timestamps: 1660777413587
    },
    {
      id: "901846b6-130e-4bae-a73c-a58b5dc8d947",
      question: "کدام اقیانوس در سواحل غربی ایالات متحده آمریکا قرار دارد",
      answers: [
        "اقیانوس آرام"
      ],
      timestamps: 1660777426282
    },
    {
      id: "1a5752a5-1d99-4330-ab47-a8afe8cb5907",
      question: "کدام اقیانوس در سواحل شرقی ایالات متحده آمریکا قرار دارد",
      answers: [
        "اقیانوس اطلس یا آتلانتیک"
      ],
      timestamps: 1660777432194
    },
    {
      id: "65f9c468-ed3b-4a85-88fe-9fbb20b165a6",
      question: "یکی از حوزه های قلمرو ایالات متحده آمریکا را نام بگیرید",
      answers: [
        "پورتوریکو",
        "جزایر ویرجین ایالات متحده آمریکا",
        "ساموآ آمریکایی",
        "جزایر ماریانای شمالی",
        "گوام"
      ],
      timestamps: 1660777449500
    },
    {
      id: "f9fd7da3-0adf-4bad-ab1b-61e7bc2048e5",
      question: "یکی از ایالت هایی که با کانادا هم مرز است را نام ببرید",
      answers: [
        "ماین",
        "نیوهمپشایر",
        "ورمونت",
        "نیویورک",
        "پنسیلوانیا",
        "اوهایو",
        "میشیگان",
        "مینسوتا",
        "داکوتای شمالی",
        "مونتانا",
        "آیداهو",
        "واشنگتن",
        "آلاسکا"
      ],
      timestamps: 1660777496388
    },
    {
      id: "787d8ccf-526f-4060-8202-08eea5e56881",
      question: "یکی از ایالت هایی که با مکزیک هم مرز است را نام بگیرید",
      answers: [
        "کالیفورنیا",
        "آریزونا",
        "نیومکزیکو",
        "تگزاس"
      ],
      timestamps: 1660777510596
    },
    {
      id: "424324da-01e0-4350-b9d0-51ac79fcbbb3",
      question: "پایتخت ایالات متحده آمریکا کجاست",
      answers: [
        "واشنگتن دی سی"
      ],
      timestamps: 1660777517115
    },
    {
      id: "9ddd8588-4271-45a6-9219-fa25793d74a3",
      question: "مجسمه آزادی در کجا قرار دارد",
      answers: [
        "بندر نیویورک",
        "همچنین می توانید نیوجرسی، نزدیکی شهر نیویورک و یا رودخانه هادسون را نام ببرید"
      ],
      timestamps: 1660777525515
    },
    {
      id: "39e81ebb-5425-45fd-aa74-86d098a41832",
      question: "چرا پرچم آمریکا 13 خط دارد",
      answers: [
        "این 13 خط نشان دهنده 13 مستعمره اولیه آمریکا می باشد"
      ],
      timestamps: 1660777532523
    },
    {
      id: "0a3c6732-6e75-494a-8d5f-ae9c32b4fb07",
      question: "چرا پرچم آمریکا دارای 50 ستاره است",
      answers: [
        "به خاطر اینکه برای هر ایالت یک ستاره وجود دارد",
        "به خاطر اینکه هر ستاره نمایانگر یک ایالت است",
        "به خاطر اینکه ایالات متحده آمریکا دارای 50 ایالت است"
      ],
      timestamps: 1660777546531
    },
    {
      id: "76e5b514-42e2-4594-957d-b003ae676949",
      question: "نام سرود ملی ایالات متحده آمریکا چیست",
      answers: [
        "پرچم ستارگان"
      ],
      timestamps: 1660777552011
    },
    {
      id: "56fc286d-095a-452c-b280-530d5bddea86",
      question: "چه زمانی روز استقلال را جشن می گیریم",
      answers: [
        "چهارم جولای هر سال"
      ],
      timestamps: 1660777558741
    },
    {
      id: "b2dfae77-99a9-4393-9f31-3cf0cb551ed6",
      question: "دو مورد از تعطیلات ملی ایالات متحده آمریکا را بگویید",
      answers: [
        "روز سال نو",
        "روز مارتین لوترکینگ جونیور",
        "روز رئیس جمهوران",
        "روز یادبود شهیدان جنگ",
        "روز استقلال",
        "روز کارگر",
        "روز کریستف کلمب",
        "روز یادبود کهنه سربازان",
        "روز شکرگذاری ",
        "روز کریسمس"
      ],
      timestamps: 1660777590461
    }
  ]

  export default citizenships