
import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import AccordionQuestion from "./AccordionQuestion";
import {useSelector} from 'react-redux'

const Citizenship = () => {
  const [test, setTest] = useState([]);
  const [page, setPage] = useState(0);

  // const { citizenship: data } = useSelector((state) => state.tests);
const {citizenship} = useSelector(state => state.tests)

  // const [allData, setAllData] = useState([])
  // const getAllGeneralTest = async() => {
  //   try {
  //     const res = await axios.get('http://localhost:5000/api/tests/generals/all')
  //     setTest(res.data)
  //   } catch (err) {
  //     toast.error(err.message)
  //   }
  // }
  // useEffect(() => {
  //   getAllGeneralTest()
  // }, [])

  useEffect(() => {
    setTest(citizenship[page]);

  }, [page]);

  useEffect(() => {
    setTimeout(() => {
      return <Loading />;
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col items-center justify-start w-screen max-w-[1540px] h-full mx-auto my-2">
      <h3 className="my-8 text-2xl font-semibold md:my-12 ">
        صد سئوال سیتیزن شیپ
      </h3>
      <p className="my-4 text-right max-w-[300px] md:max-w-[650px] mb-4 md:mb-12">
        این سئوالات به شما کمک می کند تا برای روز امتحان آماده شوید. به یا داشته
        باشید که تمامی این سئوالات به زبان انگلیسی خواهد بود و اینجا تنها برای
        آشنایی شما با ایالات متحده آمریکا و روز امتحان به زبان فارسی گرد هم آمده
        اند. برای شما در روز امتحان آرزوی موفقیت می کنیم
      </p>
      <h3 className="px-4 py-2 mb-4 font-semibold text-white rounded-md shadow-md bg-sky-800">
        <span className="font-semibold">{page + 1}</span> سئوالات بخش
      </h3>
      <div className="w-full mx-auto  rounded-md max-w-[1000px] grid md:grid-cols-[250px_minmax(750px,_1fr)_100px] md:gap-8">
        <ul className="flex flex-col items-center justify-start gap-2 py-4 md:gap-4 md:py-12">
          { citizenship &&
                citizenship.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => setPage(index)}
                  className="w-full px-8 py-2 font-semibold text-center text-white bg-teal-800 rounded-md shadow-md cursor-pointer"
                >
                  <span>{index + 1}</span> سئوالات بخش
                </li>
              );
            })}
        </ul>

        <div className="p-2">
          {test &&
            test.map((exam) => <AccordionQuestion key={exam.id} item={exam} />)}
        </div>
      </div>
    </div>
  );
};

export default Citizenship;
