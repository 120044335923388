import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";

const TestItem = ({ title, desc, link, img, bg, icon}) => {
  return (
    <div className={`flex flex-col items-center justify-center  text-white rounded-b-md shadow-md`} style={{background: bg}}>
      <header className="text-white ">
        <div className="mb-4 w-full  md:h-[28rem]">
          <img src={img} alt="test" className="object-cover w-full h-full"/>
        </div>
        <h3 className="flex items-center justify-center gap-4">
          <span className="inline-block text-xl font-semibold">{title}</span>
          {icon}
        </h3>
      </header>
      <p className="px-4 py-2 text-right md:px-10 md:py-3">{desc}</p>
      <p className="flex items-center self-start justify-center w-full gap-4 py-4 font-bold text-white rounded-md cursor-pointe" style={{background: bg === '#1e293b' ? '#115e59' : '#1e293b'}}>
        <AiOutlineArrowLeft className="w-6 h-6 font-bold"/>
        <Link to={link}>مشاهده صفحه</Link>
      </p>
    </div>
  );
};

export default TestItem
