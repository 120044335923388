import React from 'react'

const Footer = () => {
  return (
    <div className='bg-[#27272a] text-[#f8fafc] w-screen p-4 pt:12 lg:pt-20'>
        <div className='flex flex-col items-center justify-center lg:max-w-[900px] mx-auto'>
            <h3 className='text-base text-center md:text-xl'>تمام محتویات و تست های این سایت رایگان می باشد اما اگر خواستید که از تیم طراحی و توسعه سایت حمایت مالی کنید به لینک زیر مراجعه نمایید</h3>
            <a href='https://www.buymeacoffee.com/janazahediC'  className='block px-8 py-3 bg-[#134e4a] text-white text-xl font-bold rounded-md shadow-md my-12'>Buy Jan A Coffe</a>
            <p>دیزاین و توسعه سایت توسط</p>
            <p>Jan A. Zahedi</p>
    
        </div>
     
    </div>
  )
}

export default Footer