import React from "react";
import { BsCheck2All } from "react-icons/bs";
const Process = () => {
  return (
    <div className="grid w-screen pt-12 bg-white text-neutral-900 md:py-40">
      <div className="flex flex-col items-center justify-center  max-w-[1240px] mx-auto">
        <h3 className="text-2xl font-semibold">این تست ها چه برتری دارند</h3>
        <div className="grid items-center justify-center gap-4 p-4 mt-12 md:grid-cols-3">
          <div className="flex flex-col items-center justify-center p-4 text-white rounded-sm shadow-sm bg-slate-800 md:col-span-2">
            <div className="flex items-center justify-center my-2 ">
              <h2 className="py-2 mr-4 text-xl font-semibold border-b-2">
                آمادگی با زبان فارسی
              </h2>
              
              <p>
                <BsCheck2All className="w-8 h-8 text-white" />
              </p>
            </div>
            <p className="text-right md:leading-6">
              برای آن عده از فارسی زبانان که با زبان انگلیسی آشنایی کافی ندارند
              این تست ها می توانند بهترین یار کمکی باشند. شما می توانید با زبان
              مادری آماده تست شوید
            </p>
          </div>
          <div className="flex flex-col items-center justify-center p-4 rounded-sm shadow-sm bg-teal-800 text-white md:h-full md:row-span-3 self-align-end max-h-[20.8rem] md:mt-[-20px]">
            <div className="flex items-center justify-center my-2 ">
              <h2 className="mr-4 text-xl font-semibold">
                سوالات و جواب های به روز
              </h2>
              <p>
                <BsCheck2All className="w-8 h-8 text-white" />
              </p>
            </div>
            <p className="text-right md:leading-8">
              تست های موجود با استفاده از جدیدترین مرجع مورد تایید اداره
              راهنمایی و رانندگی تهیه و ترتیب شده است. جدیدترین کتابچه راهنما در
              سال ۲۰۲۲ به دست نشر سپرده شده است.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center p-4 text-white rounded-sm shadow-sm bg-slate-800">
            <div className="flex items-center justify-center my-2 ">
              <h2 className="mr-4 text-xl font-semibold">
                تنوع سوالات و امتحانات
              </h2>
              <p>
                <BsCheck2All className="w-8 h-8 text-white" />
              </p>
            </div>
            <p className="text-right md:leading-8">
              ما کوشش کرده ایم که شما نه تنها خود را برای سوالات و امتحانات
              اماده سازید بلکه تا جای امکان با تمامی قوانین و علامات موجود در
              جاده های کالیفورنیا آشنا شوید
            </p>
          </div>
          <div className="flex flex-col items-center justify-center p-4 text-white bg-teal-800 rounded-sm shadow-sm">
            <div className="flex items-center justify-center my-2 ">
              <h2 className="mr-4 text-xl font-semibold">سوالات پر تکرار</h2>
              <p>
                <BsCheck2All className="w-8 h-8 text-white" />
              </p>
            </div>
            <p className="text-right md:leading-8">
              سوالات مورد استفاده در این وبسایت عموما بصورت پرتکرار و چندین باره
              در امتحانات اداره راهنمایی و رانندگی و همچنین سیتیزن شیپ استفاده
              شده اند
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
