import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);



  return (
    <div className="relative w-full p-4 mx-auto text-white bg-transparent bg-gradient-to-tr from-neutral-900 to-neutral-700">
      <div className="container flex items-center justify-between mx-auto">
        <ul className="items-center justify-center hidden lg:flex">
          <li className="px-4 py-2 mr-4 text-base font-semibold text-center bg-teal-800 rounded-md cursor-pointer lg:text-xl lg:px-6 lg:py-3 hover:text-teal-600 hover:bg-white">
            <a href="https://www.buymeacoffee.com/janazahediC">
              Buy Jan A Coffee
            </a>
          </li>
          <li className="mr-10 text-xl font-semibold cursor-pointer">
            <NavLink className="hover:text-neutral-200" to="/about">
              درباره ما
            </NavLink>
          </li>
          

          <li className="mr-10 text-xl font-semibold cursor-pointer">
            <NavLink className="hover:text-neutral-200" to="/tests">
              تست ها
            </NavLink>
          </li>

          <li className="text-xl font-semibold cursor-pointer">
            <NavLink className="hover:text-neutral-200" to="/">
              صفحه اصلی
            </NavLink>
          </li>
        </ul>

        <button className="lg:hidden" onClick={() => setShowMenu(!showMenu)}>
          {showMenu ? (
            <AiOutlineClose className="w-6 h-6" />
          ) : (
            <AiOutlineMenu className="w-6 h-6" />
          )}
        </button>
        <h2 className="text-2xl font-semibold md:text-3xl">
          <NavLink to="/">فرشت</NavLink>
        </h2>
        {showMenu && (
          <div className="absolute right-0 top-14 w-full h-[88.3vh] bg-white text-neutral-900 flex flex-col items-center  justify-between z-40">
            <div className="flex flex-col items-end self-end justify-between gap-4 mt-8 mr-8">
              <NavLink
                className="p-1 text-xl font-semibold cursor-pointer"
                to="/"
                onClick={() => setShowMenu(false)}
              >
                صفحه اصلی
              </NavLink>
              <NavLink
                className="p-1 text-xl font-semibold cursor-pointer"
                to="/tests"
                onClick={() => setShowMenu(false)}
              >
                تست ها
              </NavLink>
              {/* {user ? (
                <button className="p-1 text-xl font-semibold cursor-pointer" onClick={logOut}>
                  خروج از سایت
                </button>
              ) : (
                 <NavLink
                 className="p-1 text-xl font-semibold cursor-pointer"
                 to="/login"
                 onClick={() => setShowMenu(false)}
               >
                 عضویت/ورود به سایت
               </NavLink>
              )} */}
             

              <NavLink
                className="p-1 text-xl font-semibold cursor-pointer"
                to="/about"
                onClick={() => setShowMenu(false)}
              >
                درباره ما
              </NavLink>
            </div>

            <div className="flex items-center justify-center w-full text-white bg-teal-800 rounded-sm">
              <a
                href="https://www.buymeacoffee.com/janazahediC"
                className="p-4 text-xl font-semibold shadow-sm cursor-pointer"
                onClick={() => setShowMenu(false)}
              >
                Buy Jan A Coffee
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
