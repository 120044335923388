import AccordionQuestion from "./AccordionQuestion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";



const General = () => {
  const [test, setTest] = useState([]);
  const [page, setPage] = useState(0);

const {general} = useSelector(state => state.tests)
  // useEffect(() => {
  //   if (loading) return;
  //   setTest(data[page]);
  // }, [loading, page]);


useEffect(() => {
  setTest(general[page])
}, [page])
 

  return (
    <div className="flex flex-col items-center justify-start w-screen max-w-[1540px] h-full mx-auto my-2 md:min-h-[520px]">
      <h3 className="my-8 text-2xl font-semibold md:my-12 ">سوالات تشریحی</h3>

      <p className="my-4 text-right">
        سئوالات تشریحی به شما کمک می کنند تا قوانین و قواعد رانندگی در ایالت
        کالیفورنیا را بهتر درک کنید. برای تحقق این امر سئوالات تشریحی جوابات
        کامل و بعضا طولانی دارند
      </p>

      <h3 className="px-4 py-2 mb-4 font-semibold text-white rounded-md shadow-md bg-sky-800">
        <span className="font-semibold">{page + 1}</span> سئوالات بخش
      </h3>
      <div className="w-full mx-auto  rounded-md max-w-[1000px] grid md:grid-cols-[250px_minmax(750px,_1fr)_100px]">
       
          <ul className="flex flex-col items-center justify-start gap-2 py-4 md:gap-4 md:py-12 md:mr-8" >
            {general.map((item, index) => {
              return (
                <li
                  key={index}
                  onClick={() => setPage(index)}
                  className="w-full px-8 py-2 font-semibold text-center text-white bg-teal-800 rounded-md shadow-md cursor-pointer"
                >
                  <span>{index + 1}</span> سئوالات بخش
                </li>
              );
            })}
          </ul>
       

        <div className="p-2">
          {test &&
            test.map((exam) => <AccordionQuestion key={exam.id} item={exam} />)}
        </div>
      </div>
    </div>
  );
};

export default General;
