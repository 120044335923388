import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component/dist-modules'
import 'react-vertical-timeline-component/style.min.css'
import {FaBookReader} from 'react-icons/fa'
import howTo from '../data'

const How = () => {
    const iconStyleElement = {background: '#065f46'}
  return (
    <div className='w-screen pt-8 md:py-24'>
        <div className='p-4 max-w-[1540px'>
            <h1 className='my-4 text-xl font-semibold text-center md:my-12 md:text-2xl lg:text-3xl'>چگونه آماده امتحان شویم</h1>
            <VerticalTimeline>
                {howTo.map((item, index) => (
                    <VerticalTimelineElement
                    key={item.id}
                    date={item.howlong}
                    dateClassName='date'
                    iconStyle={iconStyleElement}
                    icon={<FaBookReader className='text-white'/>}
                    >
                        <h3 className='font-semibold vertical-timeline-title'>{item.title}</h3>
                        <p id='description'>{item.desc}</p>
                        
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </div>
    </div>
  )
}

export default How