const paginate = (howManyItems, tests) => {
    const itemPerPage = howManyItems; 
    const page = Math.ceil(tests.length / itemPerPage)

    const newTests = Array.from({length:page}, (item, index) => {
        const start = index * itemPerPage; 
        return tests.slice(start, start + itemPerPage)
    })

    return newTests
}

export default paginate