import React from "react";
import AboutSvg from "../components/aboutPage/AboutSvg";

const About = () => {
  return (
    <div className="w-screen max-w-[1540px] mx-auto h-full my-12">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold md:text-4xl my-8 ">سلام</h2>
        <div className="max-w-[800px] flex-col flex items-center justify-center gap-8">
          <p className="p-4 text-neutral-600 text-right leading-7 md:leading-9">
            ما یک تیم کوچک اما به هدفی بزرگ هستیم. سعی ما بر این است که منابع و
            مواد مورد ضرورت برای دست یابی به مهارت های مورد نیاز برای زندگی در
            ایالات متحده آمریکا را برای فارسی/دری زبانان عزیز فراهم سازیم. این
            هدف با ایجاد اولین وبسایت برای آمادگی امتحانات راهنمایی و رانندگی به
            زبان فارسی و همچنین سوالات مربوط به امتحان سیتیزن شیپ کلید خورده
            است. ما وقت قابل توجهی برای جمع آوری و سازماندهی این وبسایت و
            محتویات آن صرف کرده ایم اما استفاده از آن برای تمامی شما عزیزان
            رایگان می باشد. در صورتی که خواستید از تیم سازنده پشتیبانی یا حمایت
            کنید می توانید به لینک پایین صفحه رفته و قهوه ای برای ما بخرید. از شما خوبان سپاسگزاریم و آرزوی موفقیت برای تان داریم
          </p>
          <AboutSvg />
        </div>
      </div>
    </div>
  );
};

export default About;
