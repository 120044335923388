import React from "react";
import { testTypes } from "../data";
import TestItem from "../components/testpage/TestItem";

const Tests = () => {
  return (
    <div className="w-screen max-w-[1200px] mx-auto p-4 md:p-12 my-8  h-full">
      <h2 className="my-4 text-2xl font-bold text-center md:my-12 md:text-3xl md:font-bold lg:text-4xl">
        تست ها
      </h2>
      <div className="grid gap-4 md:grid-cols-2">
      {testTypes.map(t => (
        <TestItem key={t.id} {...t}/>
      ))}
      </div>
    </div>
  );
};

export default Tests;
