import explainImg from './images/photo/explanatory_bg.jpg'
import signImg from './images/photo/signs_bg.jpg'
import quizImg from './images/photo/quiz_bg.jpg'
import citizenshipImg from './images/photo/citizenship_bg.jpg'
import unknownProfile from './images/photo/unknow_profile.jpg'
import {GiPassport} from 'react-icons/gi'
import {TbRepeat} from 'react-icons/tb'
import {BsSignpostSplit,BsInfoSquare} from 'react-icons/bs'

const howTo = [
  {
    id: 1,
    title: "مطالعه کتاب رهنمود",
    howlong: "سه الی چهار روز",
    desc: "در قدم اول کتاب رهنمود جامع رانندگی دی ام وی را مطالعه کنید. این کتاب کامل ترین مرجع برای آمادگی است",
    link: "/",
  },
  {
    id: 2,
    title: "سوالات عمومی",
    howlong: "دو الی سه روز",
    desc: "بعد از مطالعه کتاب شما می توانید به بخش سوالات عمومی این سایت مراجعه کنید. سوالات کامل و جامعی برای آمادگی هر چه بیشتر شما ترتیب شده است",
    link: "/",
  },
  {
    id: 3,
    title: "علایم رانندگی",
    howlong: "دو الی سه روز",
    desc: "یک بخش کامل برای علایم رانندگی در نظر گرفته شده است. شما می توانید بیشتر از شصت عدد از علایم رانندگی به علاوه معنی آن را در این وبسایت بیابید",
    link: "/",
  },
  {
    id: 4,
    title: "سوالات امتحانی",
    howlong: "سه الی چهار روز",
    desc: "بیش از دو صد سوال برای آمادگی شما طراحی شده است. شما می توانید با جواب دادن به این سوالات تمام نقاط ضعف و قوت خود را شناسایی کرده و هرچه بیشتر آماده امتحان شوید",
    link: "/",
  },
  {
    id: 5,
    title: "سیتیزن شیپ",
    howlong: "دو هفته",
    desc: "شما می توانید بدون آمادگی خاص به سراغ این سوالات بروید. به یاد داشته باشید که هر چه توانایی بیشتر در زبان انگلیسی داشته باشید و در مورد ایالات متحده آمریکا و فرهنگ و تاریخ آن بدانید بهتر است",
  },
];

export const testimonials = [
  {
    id: 1,
    name: "نام شما در اینجا",
    date: "تاریخی که شما امتحان را پاس کرده اید",
    quote: "جمله شما درباره این وبسایت و تست های آن",
    img: unknownProfile,
  },
  {
    id: 2,
    name: "نام شما در اینجا",
    date: "تاریخی که شما امتحان را پاس کرده اید",
    quote: "جمله شما درباره این وبسایت و تست های آن",
    img: unknownProfile,
  },
  {
    id: 3,
    name: "نام شما در اینجا",
    date: "تاریخی که شما امتحان را پاس کرده اید",
    quote: "جمله شما درباره این وبسایت و تست های آن",
    img: unknownProfile,
  },
  {
    id: 4,
    name: "نام شما در اینجا",
    date: "تاریخی که شما امتحان را پاس کرده اید",
    quote: "جمله شما درباره این وبسایت و تست های آن",
    img: unknownProfile,
  },
  {
    id: 5,
    name: "نام شما در اینجا",
    date: "تاریخی که شما امتحان را پاس کرده اید",
    quote: "جمله شما درباره این وبسایت و تست های آن",
    img: unknownProfile,
  },
];

export const testTypes = [
  {
    id: 1,
    title: "سوالات تشریحی",
    desc: "سوالاتی که در این بخش تهیه شده اند بصورت سوال و جواب می باشند. یعنی شما ابتدا سوال را خوانده و و در صورتی که جواب آن را ندانستید می توانید با کلیک بر روی دکمه مقابل سوال جواب آن را ببینید",
    link: "/quizes/general",
    img: explainImg,
    bg: '#115e59', 
    icon: <BsInfoSquare className='w-6 h-6'/>
  },
  {
    id: 2,
    title: "علایم راهنمایی و رانندگی",
    desc: "بیش از شصت علامت راهنمایی و رانندگی برای درک هر چه بهتر و بیشتر علایم رانندگی در این بخش گردآوری شده است. علایم رانندگی به بخش های مختلفی تقسیم شده اند اما شما باید اکثریت آن را بدانید",
    link: "/quizes/signs",
    img: signImg, 
    bg: '#1e293b', 
    icon: <BsSignpostSplit  className='w-6 h-6'/>
  },
  {
    id: 3,
    title: "سئوالات امتحانی پرتکرار",
    desc: " سوالات این بخش شما را برای صحنه امتحان آماده می سازند. سوالات دارای چهار جواب هستند و اگر خواستید شما می توانید جواب درست سوال را نیز ببینید. بیشتر از صد سوال پرتکرار در این بخش گردهم آمده اند",
    link: "/quizes/exams",
    img: quizImg, 
    bg: '#1e293b', 
    icon: <TbRepeat  className='w-6 h-6'/>
  },
  {
    id: 4,
    title: "صد سئوال سیتیزن شیپ",
    desc: "این بخش شما را با صد سئوال سیتیزن شیپ آشنا می سازند. متبع مورد استفاده ما برای تهیه این سوالات وبسایت رسمی مربوطه و همچنین کانال های معتبر و پربازدید یوتیوب می باشند",
    link: "/quizes/citizenship",
    img: citizenshipImg, 
    bg: '#115e59', 
    icon: <GiPassport  className='w-6 h-6'/>
  }

];
export default howTo;
